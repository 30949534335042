import { Button, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector ,useDispatch} from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CartApi from "../../../api/CartApi";
import Modify from "../../../commons/Modify";
import MainContext from "../../../context/MainContext";
import { loadCardAction } from "../../../redux/actions/cardActions";

const Meals = (props) => {
  const [modalOrder, setModalOrder] = useState(false);
  const [activeMeal, setActiveMeal] = useState("");
  const [activeOrder, setActiveOrder] = useState(null);
  const navigate = useNavigate();
  const dispatch=useDispatch()
  const [data, setData] = useState([]);
  const context = useContext(MainContext);
  const packet = useSelector((state) => state.packet);
  const params = useParams();
  const jwt = useSelector((state) => state.jwt);
  const newOrder = (type) => {
    setActiveMeal(type);
    setModalOrder(true);
  };
  const loadOrderInfo = async (oid) => {
    try {
      setActiveOrder(oid);
      const _res = await CartApi.listOrder(oid, jwt);
      console.log("load foods day",_res)
      dispatch(loadCardAction(_res.data))
      setData(_res.data);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  useEffect(() => {
    // console.log(packet.orders[1].p_day,props.selectedDate.locale("fa").format("jYYYY/jMM/jDD"),444444444444);
   console.log(props.selectedDate,22)
    packet?.orders?.map((order) => {
      if (
        order.p_day == props.selectedDate.locale("fa").format("jYYYY/jMM/jDD")
      ) {
        loadOrderInfo(order.id);
      }
    });
  }, [props.selectedDate]);
  return (
    <div className="meals">
      <div className="d-flex align-items-center">
        <img src="/icon/tip1.svg" alt="" className="ps-2" />
        <span style={{ color: "#198754" }}>
          وعده های انتخابی شما در هر روز، یه صورت پکیج در ساعت انتخابی شما ارسال
          خواهد شد.
        </span>
      </div>
      <div className="mt-3">
        <span>ابتدا روز مورد نظر خود را انتخاب کنید و سپس غذای خود را در وعده های تعیین شده مشخص کنید.</span>
      </div>
      {data?.map((meal, index) => {
        return (
          <div className="meal" key={index}>
            <h6>{meal.meal} </h6>
            <div className="recipes">
              {meal?.product?.map((product, index) => (
                <div className="recipe" key={index}>
                  <div className="recipe-box position-relative">
                    <img
                      src={product.product.cover}
                      className="h-100 w-100 "
                      alt=""
                    />

                    <Modify food={product} meal={meal}/>
                  </div>
                  <span>{product.product.name}</span>
                </div>
              ))}

              <div
                className="plus-recipe"
                onClick={() => newOrder("BreakFast")}
              >
                <div className="plus-box">
                  <img src="/icon/plus.svg" className="img-fluid " alt="" />
                </div>
                <span>اضافه کردن غذا</span>
              </div>
            </div>
          </div>
        );
      })}

      <Modal
        closable={true}
        onCancel={() => setModalOrder(false)}
        title={"از مسیرهای زیر میتونی غذاتو انتخاب کنی."}
        visible={modalOrder}
        footer={false}
      >
        <div className="modal-select-order  text-center">
          <div
            className="item"
            role={"button"}
            onClick={() => navigate(`/pack/${params.pid}/order/${activeOrder}/foods/${activeMeal}`)}
          >
            <img src="/images/order.svg" className="p-3 img-fluid" alt="" />
            <span className="mt-2 mb-2">غذاهای هیلی میلی</span>
          </div>
          <div className="item">
            <img src="/images/make.svg" className="p-3 img-fluid" alt="" />
            <span className="mt-2 mb-2">غذاهای خودتو بساز</span>
          </div>
          <div className="item pb-2">
            <img src="/images/list.svg" className="p-3 img-fluid" alt="" />
            <span className="mt-2  ">لیست من</span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Meals;
