import Global from "../config.json";
import httpService from "../services/httpService";
const FoodApi = {
  async loadFoods(q){
    let resStatus = 0;
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}product/?name=${q}`
      )
      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res = json.data.slice(0,11);
            break;
        
        }
      })
      

    return res;
  }
}
export default FoodApi;