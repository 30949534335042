import React, { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";

const StepBody = forwardRef((props, ref) => {
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const step4Ref = useRef();
  const step5Ref = useRef();
  useImperativeHandle(ref, () => ({
    async onSubmit() {
      switch (props.activeStep) {
        case 1:
          step1Ref.current.onSubmit();
          break;
        case 2:
          step2Ref.current.onSubmit();
          break;
        case 3:
          step3Ref.current.onSubmit();
          break;
        case 4:
          step4Ref.current.onSubmit();
          break;
        case 5:
          step5Ref.current.onSubmit();
          break;
      }
    },
  }));

  return (
    <div className="step-body mt-5 pt-3 mb-5">
      <div
        className="step1"
        style={{ display: props?.activeStep === 1 ? "" : "none" }}
      >
        <Step1 ref={step1Ref} />
      </div>
      <div
        className="step2"
        style={{ display: props?.activeStep === 2 ? "" : "none" }}
      >
        <Step2 ref={step2Ref}/>
      </div>
      <div
        className="step3"
        style={{ display: props?.activeStep === 3 ? "" : "none" }}
      >
        <Step3 ref={step3Ref}/>
      </div>
      <div
        className="step4"
        style={{ display: props?.activeStep === 4 ? "" : "none" }}
      >
        <Step4 ref={step4Ref}/>
      </div>
      <div
        className="step5"
        style={{ display: props?.activeStep === 5 ? "" : "none" }}
      >
        <Step5 ref={step5Ref}/>
      </div>
    </div>
  );
});

export default StepBody;
