import Global from "../config.json";
import httpService from "../services/httpService";
const CartApi = {
  
  async listOrder(oid, jwt) {
    let resStatus = 0;
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}cart/${oid}/order/`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "order day");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async listPacket(jwt) {
    let resStatus = 0;
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}cart/`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "get list cart");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async getAddress(id,jwt) {
    let resStatus = 0;
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}cart/${id}`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "get list address");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async detailPacket(jwt) {
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}cart?detail=true`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "get detail  cart");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async loadOrdersState( jwt) {
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}cart/order-state/`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "order state");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
};
export default CartApi;
