import React, { useEffect, useRef, useState } from "react";
import moment from "jalali-moment";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-elastic-carousel";
import { BiDish } from "react-icons/bi";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { useContext } from "react";
import MainContext from "../context/MainContext";
import { loadPackagesAction } from "../redux/actions/packagesActions";
import PackageApi from "../api/PackageApi";
import { loadPackageCalendarAction } from "../redux/actions/packetActions";
const SelectDate = (props) => {
  const [days, setDays] = useState([]);
  const context = useContext(MainContext);
  const [pid, setPid] = useState(0)
  const [dateSelected, setDateSelected] = useState(moment());
  const packages = useSelector((state) => state.packages);
  const packet = useSelector((state) => state.packet);
  const dispatch = useDispatch();
  const daysBox = useRef();
  const jwt = useSelector((state) => state.jwt);
  const loadPacketActive = async () => {
    try {
      dispatch(loadPackagesAction(jwt, context));
    } catch (error) {
      context.handleMsg(error, "error");
    }
  };

  useEffect(() => {
    loadPacketActive();
  }, []);
  const nextDay = () => {
    let _date = dateSelected.clone().add(1, "days");
    setDateSelected(_date);
    props.onSelectDate(_date);
    daysBox.current.slidePrev();
  };
  const beforeDay = () => {
    let _date = dateSelected.clone().subtract(1, "days");
    setDateSelected(_date);
    props.onSelectDate(_date);
    daysBox.current.slideNext();
  };
  const getDaysArrayByMonth = () => {
    var arrDays = [];
    var current = moment(
      packet.from_date,
      "YYYY-MM-DD[T]hh:mm:ss.SSS[Z]"
    ).locale("fa");
    while (
      current.locale("fa").format("jDD") !==
      moment(packet.to_date, "YYYY-MM-DD[T]hh:mm:ss.SSS[Z]")
        .locale("fa")
        .format("jDD")
    ) {

      arrDays.push(current.clone());

      current = current.clone().add(1, "days");
      console.log(arrDays);
    }
    setDateSelected(
      moment(packet.from_date, "YYYY-MM-DD[T]hh:mm:ss.SSS[Z]").locale("fa")
    );
    props.changeSelectedDate(moment(packet.from_date, "YYYY-MM-DD[T]hh:mm:ss.SSS[Z]").locale("fa"));
    return arrDays;
  };
  // const loadCalendar = async () => {
  //   try {
  //     dispatch(loadPackageCalendarAction(pid, packages[0].id, jwt, context));
  //   } catch (e) {
  //     context.handleMsg(e);
  //   }
  // };
  const loadPacket=async()=>{
    try {
        const _activaPack=packages.filter(p=>p.state===props.state)
        console.log(_activaPack,2)
        if(_activaPack.length==0)
          return
        const _res=await PackageApi.loadPacket(jwt)
        if(_res){
          setPid(_res[0].id)
          dispatch(loadPackageCalendarAction(_res[0].id,_activaPack[0].id,jwt,context))
        }
    } catch (error) {
      context.handleMsg(error,"error")
    }
  }
  useEffect( () => {
    if (packages?.length) {
       loadPacket();
    }
  }, [packages]);
  useEffect(() => {
    if (packet) setDays(getDaysArrayByMonth());
  }, [packet]);
  const checkDay = (day) => {
    if (dateSelected.locale("fa").format("jDD") == day.format("jDD"))
      return "active";

    return "";
  };
  const selectDay = (day) => {
    props.changeSelectedDate(day);

    setDateSelected(day);
  };
  const checkOrder = (day) => {
    let res = null;

    packet?.orders?.map((order) => {
      if (
        order.p_day === day.locale("fa").format("jYYYY/jMM/jDD") &&
        order.order_count > 0
      ) {
        res = (
          <div
            className="position-absolute  d-flex d-f-j-a "
            style={{ top: "75%", width: "115%" }}
          >
            <small className="border border-1 rounded-3 b-orange  bg-white p-0 ps-2 pe-2 b-c-orange text-dark ">
              {" "}
              {order.order_count}
            </small>

            <BiDish
              className=" c-orange "
              fill="rgba(236, 119, 45, 1)"
              stroke={"rgba(236, 119, 45, 1)"}
              size={20}
              style={{ right: "-25%" }}
            />
          </div>
        );
      }
      return;
    });
    return res;
  };
  return (
    <div>
      {props.labelDate && (
        <h6>تاریخ امروز: {moment().format("jYYYY/jMM/jDD")}</h6>
      )}
      <div className="select-date w-100">
        <BsArrowRight
          role={"button"}
          className="arrow c-orange"
          size={40}
          onClick={beforeDay}
          alt=""
        />
        <Carousel
          itemsToShow={7}
          className="days"
          ref={daysBox}
          initialActiveIndex={12}
          showArrows={false}
          pagination={false}
        >
          {days.map((day, index) => {
            return (
              <span
                key={index}
                className={`day ${checkDay(
                  day.locale("fa")
                )} position-relative`}
                onClick={() => selectDay(day)}
              >
                {day.locale("fa").format("jDD")}
                <br />
                {day.format("dddd")}
                {checkOrder(day)}
              </span>
            );
          })}
        </Carousel>

        <BsArrowLeft
          role={"button"}
          className="arrow  c-orange"
          onClick={nextDay}
          size={40}
          alt=""
        />
      </div>
    </div>
  );
};

export default SelectDate;
