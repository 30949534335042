import React, { useState } from "react";
import Tabs from "../../../commons/Tabs";
import Panel from "../../../layouts/Panel";

import ActivePackage from "./components/ActivePackage";
import History from "./components/History";
const Orders = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      icon: <img src="/icon/basket-cur.svg" width={30} />,
      title: "سفارش جاری",
      count: 0,
    },
    {
      icon: <img src="/icon/basket-rec.svg" width={30} />,
      title: "تحویل داده شده",
      count: 0,
    },
    {
      icon: <img src="/icon/basket-fail.svg" width={30} />,
      title: "لغو شده",
      count: 0,
    },
  ];


  return (
    <>
      <div className="orders">
        <Tabs data={tabs} activeTab={setActiveTab} />

        {activeTab === 0 && <ActivePackage />}
        {activeTab === 1 && <History />}
      </div>
    </>
  );
};

export default Orders;
