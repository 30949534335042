import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import BasketApi from "../../../api/BasketApi";
import MainContext from "../../../context/MainContext";

const BasketInfo = () => {
    const context= useContext(MainContext)
    const jwt=useSelector(state=>state.jwt)
    const [paymentInfo, setPaymentInfo] = useState([])
    const loadPayment=async()=>{
        try {
           const _res=await BasketApi.loadActivePackage(jwt)
           setPaymentInfo(_res[0])
        } catch (error) {
            context.handleMsg(error,"error")
        }
    }
    useEffect(()=>{
        loadPayment() 
    },[])
  return (
    <ul className="list-group list-group-flush">
      <li className="list-group-item d-f-j-a">
        <span>جمع قیمت ({paymentInfo?.package} غذا)</span>
        <span>{paymentInfo?.food_price??0} تومان</span>
      </li>
      <li className="list-group-item  d-f-j-a">
        <span> هزینه ارسال</span>
        <span>{paymentInfo?.delivery*paymentInfo?.days} تومان</span>
      </li>
      <li className="list-group-item  d-f-j-a">
        <span>مبلغ قابل پرداخت</span>
        <span>  {Number(paymentInfo?.amount??0 *paymentInfo?.days??0* paymentInfo?.tax??0).toFixed(0)} تومان</span>
      </li>
      {/* <li className="list-group-item  d-f-j-a">
        <span>سود شما </span>
        <span></span>
      </li> */}
    </ul>
  );
};

export default BasketInfo;
