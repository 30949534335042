import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PackageApi from "../../api/PackageApi";
import MainContext from "../../context/MainContext";
import Master from "../../layouts/Master";

const Pack = () => {
  const params = useParams();
  const navigate = useNavigate();
  const context = useContext(MainContext);
  const [pack, setPack] = useState(null);
  const jwt = useSelector((state) => state.jwt);
  useEffect(() => {
    PackageApi.loadPackage(params.pid)
      .then((res) => {
        setPack(res);
      })
      .catch((e) => {
        context.handleMsg(e, "error");
      });
  }, [params?.pid]);
  const goOrderPage = () => {
    navigate(`/pack/${params?.pid}/order`);
  };
  const deletePack = async () => {
    try {
      let _res = await PackageApi.deletePackage(params.pid, jwt);
    } catch (e) {
      console.log(e);
    }
  };
  return (
      <div className="container package mb-5">
        <div className="position-relative" >
          <div className="define-box">
            <img src={pack?.image} alt={pack?.name} className="img-fluid" />
            <div className="w-100 position-relative">
              <h5>{pack?.name}</h5>
              {pack?.long_description}
            </div>

            <button
              onClick={goOrderPage}
              className="show-pack btn  btn--green p-2 pe-4 ps-4"
            >
              مشاهده و ثبت وعده
            </button>
            {/* <button onClick={deletePack}>
              حذف
            </button> */}
          </div>
        </div>
      </div>
  );
};

export default Pack;
