import UserApi from "../../api/UserApi";

// export const loadBMIAction = () => {
//   return async (dispatch) => {
//     const jwt = await storage.loadJWT();
//     await dispatch({ type: "JWT", payload: jwt });
//   };
// };
export const getPropertyBMIAction = (token,context) => {
  return async (dispatch) => {
    try {
      let _bmi =await UserApi.loadBMI( token);
      await dispatch({ type: "BMI", payload: _bmi });
    } catch (e) {
      context.handleMsg(e);
    }
  };
};
export const setPropertyBMIAction = (gender, age, weight, height, token,context) => {
  return async (dispatch) => {
    try {
      let _bmi =await UserApi.computeBMI(gender, age, weight, height, token);
      await dispatch({ type: "BMI", payload: _bmi });
    } catch (e) {
      context.handleMsg(e,"error");
    }
  };
};
