import { Input, InputNumber, Radio, Select, TimePicker } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import QuestionsAPI from "../../../../api/QuestionsAPI";
import { useSelector } from "react-redux";

const INPUTTEXT = (props) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    props.question.newAnswer= props.question?.answer?.answer
  },[])
  useEffect(() => {
    if (props.question.parent_question) {
      let _q = props.questions.find(
        (qs) => qs.id === props.question.parent_question
      );

      if (_q?.answer?.newAnswer == "n") setDisabled(true);
      else setDisabled(false);
    }
  });
  const onChange = (e) => {
    props.question.newAnswer = e.target.value;
  };

  return <Input onChange={onChange} defaultValue={props.question?.answer?.answer} disabled={disabled} placeholder="وارد نمایید" />;
};
const INPUTNUMBER = (props) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    props.question.newAnswer= props.question?.answer?.answer
  },[])
  useEffect(() => {
    if (props.question.parent_question) {
      let _q = props.questions.find(
        (qs) => qs.id === props.question.parent_question
      );
      if ( _q?.newAnswer == "n") setDisabled(true);
      else setDisabled(false);
    }
  });
  const onChange = (value) => {
    props.question.newAnswer = value;
  };

  return <InputNumber defaultValue={props.question?.answer?.answer} min={0}  disabled={disabled} onChange={onChange} placeholder="00" />;
};
const INPUTCHOICE = (props) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    props.question.newAnswer= props.question?.answer?.answer
  },[])
  useEffect(() => {
    if (props.question.parent_question) {
      let _q = props.questions.find(
        (qs) => qs.id === props.question.parent_question
      );

      if ( _q?.answer?.newAnswer == "n") setDisabled(true);
      else setDisabled(false);
    }
  });
  const onChange = (value) => {
    props.question.newAnswer = value;
  };

  return (
    <Select
      placeholder="انتخاب کنید"
      style={{ width: "100%" }}
      onChange={onChange}
      disabled={disabled}
      defaultValue={props.question?.answer?.answer} 
    >
      {props.question.options.map((item, index) => (
        <Select.Option key={index} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
const INPUTSELECT = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [,forceUpdate]=useState(0)
  const jwt=useSelector(state=>state.jwt)
  useEffect(() => {
    if (props.question.options.length == 0) loadOptions();
    props.question.newAnswer= props.question?.answer?.answer
  }, []);
  useEffect(() => {
    if (props.question.parent_question) {
      let _q = props.questions.find(
        (qs) => qs.id === props.question.parent_question
      );

      if ( _q?.answer?.newAnswer == "n") setDisabled(true);
      else setDisabled(false);
    }
  });
  const loadOptions = async () => {
    try {
      const _res = await QuestionsAPI.loadOptions(props.question.code, jwt);
      props.question.options = _res;
      console.log(props.question.text,_res.length,_res)
      forceUpdate(Math.random())

    } catch (e) {}
  };

  const onChange = (v) => {
    props.question.newAnswer = v;
  };

  return (
    <Select
      style={{ width: "100%" }}
      placeholder="انتخاب کنید"
      showSearch
      onChange={onChange}
      defaultValue={props.question?.answer?.answer} 
      filterOption={(input, option) =>
        (option?.children ).toLowerCase().includes(input.toLowerCase())
      }
      disabled={disabled}
    >
      {props.question.options.map((item, index) => (
        <Select.Option key={index} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
const INPUTYESNO = (props) => {
  useEffect(() => {

    props.question.newAnswer= props.question?.answer?.answer
  }, []);
  const onChange = (e) => {
    props.question.newAnswer = e.target.value;
    props.onUpdate();
  };

  return (
    <Radio.Group onChange={onChange} defaultValue={props.question?.answer?.answer}>
      <Radio value={"y"}>بله</Radio>
      <Radio value={"n"}>خیر</Radio>
    </Radio.Group>
  );
};
const INPUTCLOCK = (props) => {
  useEffect(() => {

    props.question.newAnswer= props.question?.answer?.answer
  }, []);
  const onChange = (time, timeString) => {
    console.log(time, timeString);
    props.question.newAnswer = timeString;
  };

  return (
    <TimePicker
      placeholder="انتخاب زمان"
      format={"HH:mm"}
      style={{ width: "max-content", direction: "ltr" }}
      onChange={onChange}
    />
  );
};
const MULTICHOICE = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [, forceUpdate] = useState(0);
  useEffect(() => {

    props.question.newAnswer= props.question?.answer?.answer
  }, []);
  useEffect(() => {
    if (props.question.parent_question) {
      let _q = props.questions.find(
        (qs) => qs.id === props.question.parent_question
      );

      if ( _q?.answer?.newAnswer == "n") setDisabled(true);
      else setDisabled(false);
    }
  });
  const onChange = (item, index, v) => {
    props.question.newAnswer[index] = { value: v, id: item.id };
    forceUpdate(Math.random())
  };
  const onChangeRadio = (e) => {
    props.question.newAnswer = e.target.value;
    forceUpdate(Math.random())

  };
  if (props.question.options[0].type === 2)
    return (
      <div className="d-flex ">
        {props.question.options.map((item, index) => {
          console.log(props.question?.newAnswer?.length>0,8888888)
          return (
            <div className="d-grid me-2">
              <label>{item.name}</label>
              <InputNumber
                disabled={disabled}
                key={index}
                min={0}
                value={props.question?.newAnswer?.length>0?props.question?.newAnswer[index]?.value:0}
                onChange={(v) => onChange(item, index, v)}
              />
            </div>
          );
        })}
      </div>
    );
  else if (props.question.options[0].type === 4 || props.question.options[0].type === 5)
    return (
      <div className="d-grid ">
        <Radio.Group disabled={disabled} value={ props.question?.newAnswer} onChange={onChangeRadio}> 
          {props.question.options.map((item, index) => {
            return <Radio value={item.id}>{item.name}</Radio>;
          })}
        </Radio.Group>
      </div>
    );
    
};
const InputType = (props) => {
  const checkType = () => {
    switch (props.data.answer_type) {
      case 0:
        return <INPUTTEXT question={props.data} questions={props.questions} />;
      case 1:
        return (
          <INPUTYESNO
            question={props.data}
            onUpdate={props.onUpdate}
            questions={props.questions}
          />
        );
      case 2:
        return (
          <INPUTNUMBER question={props.data} questions={props.questions} />
        );
      case 3:
        return <INPUTCLOCK question={props.data} />;
      case 4:
        return (
          <MULTICHOICE question={props.data} questions={props.questions} />
        );

      case 5:
        return (
          <INPUTSELECT question={props.data} questions={props.questions} />
        );
      case 6:
        return (
          <INPUTCHOICE question={props.data} questions={props.questions} />
        );
    }
  };
  return checkType();
};

export default InputType;
