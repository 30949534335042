import React from "react";
import { message } from "antd";

const handleMsg = {
  async preparingMsg(msg) {
    let _error = "";
    Object.keys(msg).forEach(function (key) {
      _error += msg[key] + "  " + ":" + key + "\n";
    });
    message.error(_error);
  },
  async handleError(msg) {
    switch (msg?.response?.status) {
      case 500:
        message.error(" مشکلی از سمت سرور پیش آماده است ");
        break;
      case 400:
        handleMsg.preparingMsg(msg.response.data);
        break;
      case 404:
        message.error(" آدرس سرویس اشتباست ");
        break;
    }
  },
  async showMsg(msg, type = null) {
    switch (type) {
      case "error":
        if (typeof msg === "string") message.error(msg);
        else handleMsg.handleError(msg);
        break;
      case "info":
        message.info(msg);
        break;
    }
  },
};

export default handleMsg;
