import { combineReducers } from "redux";
import {jwtReducer } from "./jwtReducer";
import {bmiReducer } from "./bmiReducer";
import {packetReducer } from "./packetReducer";
import {cardReducer } from "./cardReducer";
import {packagesReducer } from "./packagesReducer";
import {basketReducer } from "./basketReducer";
export const reducers = combineReducers({
  jwt: jwtReducer,
  bmi: bmiReducer,
  basket: basketReducer,
  card: cardReducer,
  packet: packetReducer,
  packages: packagesReducer,
});
