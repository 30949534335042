import Global from "../config.json";
import httpService from "../services/httpService";
const CommentsAPI = {
  

  async loadLanding() {
    let res = [];
    await httpService
      .get(
        `${Global.HealyMealy}landing/comments/`
    
      )
      .then((json) => {
        console.log(json, "load comments");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  
};
export default CommentsAPI;
