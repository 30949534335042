import PackageApi from "../../api/PackageApi";

export const loadPackagesAction = (jwt, context) => {
  return async (dispatch) => {
    try {
      let _res = await PackageApi.loadActivePackages(jwt);
      await dispatch({ type: "PACKAGES", payload: _res });
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
};
export const startPackagesAction = (pack) => {
  return async (dispatch) => {
    await dispatch({ type: "PACKAGES", payload: [pack] });
  };
};
