import React, { useState } from "react";
import moment from "jalali-moment";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect } from "react";
import UserApi from "../../../../api/UserApi";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  scales: {
    y: {
      max: 4000,
      min: 0,
    },
  },
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

const labels = Array(31 - 1 + 1)
  .fill()
  .map((_, idx) => 1 + idx);
export const data = {
  labels,
  datasets: [
   
    {
      label: "کالری مصرفی شما",
      data: labels.map(() => Math.random()),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const Chart = () => {
  const navigate=useNavigate()
  const [ChartCalories, setChartCalories] = useState([])
  const jwt=useSelector(state=>state.jwt)
  const alert = (
    <Result
      className="bg-white"
      title="در حال حاضر شما پکیج فعالی ندارید."
      extra={
        <Button
          type="primary"
          onClick={() => navigate("/#packages")}
          shape="round"
          className="w-100  mt-2 mb-4 c-btn btn-success border border-0 "
        >
          مشاهده پکیج های غذایی
        </Button>
      }
    />
  );
  const loadCalories=async()=>{
    try {
      const _res=await UserApi.loadCalories(jwt);
      console.log(_res)
      setChartCalories(_res)
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    loadCalories()
  },[])

  return (
    <div>
      <div className="d-f-j-a">
        <h6>نمودار مصرف کالری</h6>
        <small>تاریخ امروز: {moment().format("jYYYY/jMM/jDD")}</small>
      </div>
      <div className="w-100 text-center fw-bold">{`< ${moment().locale("fa").format("jMMMM")} >`}</div>
      <div className="position-relative chart">
        {ChartCalories.length>0? null:<div className="position-absolute alert">{alert}</div>}
        <Line options={options} data={data} />
      </div>
    </div>
  );
};

export default Chart;
