import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "jalali-moment";
import { useSelector } from "react-redux";
import MainContext from "../context/MainContext"
import Carousel from "react-elastic-carousel";
import { BiDish } from "react-icons/bi";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
const SelectDatePackage = (props) => {
  const [days, setDays] = useState([]);
  const [dateSelected, setDateSelected] = useState(props.selectedDate.locale("fa")??moment());
  const packet = useSelector((state) => state.packet);
  const context=useContext(MainContext)
  const daysBox = useRef();
  const nextDay = () => {
    let _date= dateSelected.clone().add(1, 'days');
    setDateSelected(_date)
    props.onSelectDate(_date)
    daysBox.current.slidePrev()
  
    };
    const beforeDay = () => {
      let _date=dateSelected.clone().subtract(1, "days")
      setDateSelected(_date)
      props.onSelectDate(_date)
      daysBox.current.slideNext()
  
  
    };
  const getDaysArrayByMonth = () => {
    // let _today=moment().locale("fa")
    var arrDays = [];
    var current =moment(packet.from_date,"YYYY-MM-DD[T]hh:mm:ss.SSS[Z]").locale("fa");
    while( current.locale("fa").format("jDD")!== moment(packet.to_date,"YYYY-MM-DD[T]hh:mm:ss.SSS[Z]").locale("fa").format("jDD")) {
       
      arrDays.push(current.clone());

       current =current.clone().add(1, "days");
    }
    // while (_amount > -16) {
    //   var current = moment().locale("fa").add(_amount, "days");
    //   arrDays.push(current);
    //   _amount--;
    // }
    setDateSelected(moment(packet.from_date,"YYYY-MM-DD[T]hh:mm:ss.SSS[Z]").locale("fa"))

    return arrDays;
  };
  
  useEffect(() => {
    if(packet){
    setDays(getDaysArrayByMonth());
    selectDay(dateSelected)
    }
  }, [packet]);
  const checkDay = (day) => {
    if (dateSelected.locale("fa").format("jDD") == day.format("jDD"))
      return "active";

    return "";
  };
  const selectDay = (day) => {
    props.changeSelectedDate(day);

    setDateSelected(day);
  };
  const checkOrder = (day) => {
    let res = null;

    packet?.orders?.map((order) => {
      if (
        order.p_day === day.locale("fa").format("jYYYY/jMM/jDD") &&
        order.order_count > 0
      ) {
        res = (
          <div
            className="position-absolute  d-flex d-f-j-a "
            style={{ top: "75%", width: "115%" }}
          >
            <small className="border border-1 rounded-3 b-orange  bg-white p-0 ps-2 pe-2 b-c-orange text-dark ">
              {" "}
              {order.order_count}
            </small>

            <BiDish
              className=" c-orange "
              fill="rgba(236, 119, 45, 1)"
              stroke={"rgba(236, 119, 45, 1)"}
              size={20}
              style={{ right: "-25%" }}
            />
          </div>
        );
      }
      return;
    });
    return res;
  };
  return (
    <div className="selected-date">
      {props.labelDate && (
        <h6>تاریخ امروز: {moment().format("jYYYY/jMM/jDD")}</h6>
      )}
      <div className="select-date w-100">
        <BsArrowRight role={"button"} className="arrow c-orange" size={40}  onClick={beforeDay} alt="" />
        <Carousel
          itemsToShow={context.checkWindow().width>480?7:4}
          className="days"
          ref={daysBox}
          initialActiveIndex={13}
          showArrows={false}
          pagination={false}
        >
          {days.map((day, index) => {
            return (
              <span
                key={index}
                className={`day ${checkDay(
                  day.locale("fa")
                )} position-relative`}
                onClick={() => selectDay(day)}
              >
                {day.locale("fa").format("jDD")}
                <br />
                {day.format("dddd")}
                {checkOrder(day)}
              </span>
            );
          })}
        </Carousel>

        <BsArrowLeft  role={"button"} className="arrow  c-orange" onClick={nextDay} size={40} alt="" />
      </div>
    </div>
  );
};

export default SelectDatePackage;
