import React from "react";
import {MdOutlineSpaceDashboard,MdFavoriteBorder,MdLogout} from "react-icons/md"
import {CgList} from "react-icons/cg"
import {BiMessageDetail} from "react-icons/bi"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeJWTAction } from "../redux/actions/jwtActions";
const MenuProfile = () => {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const logOut=()=>{
    dispatch(removeJWTAction())
    navigate("/")
  }
  return (
    <ul className="list-group list-group-flush text-end"  >
      <li className="list-group-item" role={"button"} onClick={()=>navigate("/panel/home")} > <MdOutlineSpaceDashboard/> داشبورد</li>
      <li className="list-group-item" role={"button"} onClick={()=>navigate("/panel/orders")}> <CgList/> سفارش ها </li>
      <li className="list-group-item" role={"button"} onClick={()=>navigate("/panel/list")}> <MdFavoriteBorder/> لیست من </li>
      <li className="list-group-item" role={"button"} > <BiMessageDetail /> پیام ها </li>
      <li className="list-group-item ps-4" role={"button"}  onClick={logOut}> <MdLogout /> خروج از حساب کاربری </li>
    </ul>
  );
};

export default MenuProfile;
