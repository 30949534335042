import { Avatar } from "antd";
import React from "react";
import { useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useSelector } from "react-redux";
import moment from "jalali-moment";
const History = () => {
  const packages = useSelector((state) => state.packages);

  return (
    <div>
      {packages.map((packet, index) => {
        if (packet.state === 4)
          return (
            <div className="d-f-j-a mt-4 border border-1 rounded p-2">
              <div className="d-f-j-a">
                <div className="ms-3">
                  <Avatar
                    src={packet?.package?.image}
                    shape="square"
                    size={60}
                  />
                </div>
                <div className="d-grid">
                  <div>{packet?.package?.name}</div>
                  <div>
                    {moment(
                      packet?.package?.created,
                      "YYYY-MM-DD[T]hh:mm:ss[Z]"
                    )
                      .locale("fa")
                      .format("jDD jMMMM jYYYY")}
                  </div>
                </div>
              </div>
              <div className="d-grid">
                <div>تعداد روز: {packet?.package?.max_day}</div>
                <div> تعداد غذا: {packet?.package?.to_date}</div>
              </div>
              <div className="d-grid">
                <div>مبلغ پرداخت شده</div>
                <div className="c-green"> {packet?.price} تومان</div>
              </div>
              <div className="c-orange " role={"button"}>
                مشاهده جزئیات <BsArrowLeft />
              </div>
            </div>
          );
      })}
    </div>
  );
};

export default History;
