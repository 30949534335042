import React, { useContext, useEffect, useState } from "react";
import { AutoComplete, Collapse } from "antd";

import RecipeApi from "../../api/RecipeApi";
import MainContext from "../../context/MainContext";
import Master from "../../layouts/Master";
import Comments from "./components/Comments";
import Steps from "./components/Steps";
import { Link, useNavigate } from "react-router-dom";
import Packages from "./components/Packages";
import Blogs from "./components/Blogs";

const Home = () => {
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [recipes, setRecipes] = useState([]);
  const [recipe, setRecipe] = useState(null);
  const [amount, setAmount] = useState(0);
  const [calories, setCalories] = useState(null);
  const [units, setUnits] = useState([]);
  const navigate=useNavigate()
  const [unit, setUnit] = useState(null);
  const [showBmiForm, setShowBmiForm] = useState(true);
  const [bmi, setBmi] = useState(null);
  const context = useContext(MainContext);
  const showPackages=()=>{
    context.scrollToEl("packages")
  }

  const queryRecipe = async (q, type = "recipe") => {
    try {
      let result = [];
      if (type == "recipe") {
        result = await RecipeApi.loadRecipe(q);
        result = result.map((res) => {
          return { value: res.name, id: res.id };
        });
        setRecipes(result);
      } else if (type == "units") {
        setRecipe(q);
        result = await RecipeApi.loadUnits(q);
        result = result.map((res) => {
          return { value: res.name, id: res.id };
        });
        setUnits(result);
      }
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
 
  useEffect(() => {
    queryRecipe("");
  }, []);
  const runCompute = () => {
    const _w = Number(weight);
    const _h = Number(height) / 100;
    if (_h && _w) {
      const r = _w / (_h * 2);
      setBmi("BMI شما " + r.toFixed(2) + " میباشد ");
    }
  };
  const changeCompute = () => {
    setShowBmiForm(!showBmiForm);
  };
  
  const runComputeCalories = async () => {
    try {
      let result = await RecipeApi.computeCalories(
        amount,
        unit,
        recipe,
        "recipe"
      );

      setCalories(`کالری مصرفی ${result.calories} می باشد`);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  const makeFood=()=>{
    alert("این قسمت در حال آماده سازیست")
  }
  return (
    // <Master>
      <>
        <div className="banner">
          <div className="container-fluid p-0">
            <div className="box">
              <div className="food">
                <div className="pattern">
                  <div className="plate" role={"button"} onClick={makeFood} >
                    <img src="/images/plate.png" />
                    <div id="motion--make-food">
                      <img src="/icon/arrow-right.svg" className="arrow" />
                      <div className="tooltip-order me-2 c-black" >
                   
                        غذا رو خودت درست کن
                      
                      </div>
                    </div>
                    <p className="subtitle">با مواد اولیه و ارگانیک</p>
                  </div>
                </div>
                <div className="p-3 slogan-1">
                  <div className="content__container">
                    <div className="content__container__text">
                      <h3 style={{ color: "white" }}>کافیه اراده کنی تا</h3>
                    </div>
                    <ul className="content__container__list">
                      <li className="content__container__list__item me-1">
                        <h3 style={{ color: "#ffb01e" }}>خوش تیپ</h3>
                      </li>
                      <li className="content__container__list__item me-1">
                        <h3
                          className="success-person"
                          style={{ color: "#ff5251" }}
                        >
                          فرد موفق
                        </h3>
                      </li>
                      <li className="content__container__list__item me-1">
                        <h3
                          className="slogan__fix_text_1"
                          style={{ color: "#00a4ec" }}
                        >
                          {" "}
                          انسان سالم{" "}
                        </h3>
                      </li>
                      <li className="content__container__list__item me-1">
                        <h3 style={{ color: "#8940ff" }}>خوش اندام</h3>
                      </li>
                    </ul>
                  </div>
                  <h3 style={{ color: "white" }}>باشی. غذات هر روز پشت دره</h3>
                  <div onClick={showPackages} id="box-show-packages">
                    <span  id="text-box-show-packages">
                      مشاهده پکیج های غذایی
                    </span>
                    <i></i>
                  </div>
                </div>
              </div>
              <div className="p-3 slogan-2">
                <div className="content__container">
                  <div className="content__container__text">
                    <h3 style={{ color: "white" }}>کافیه اراده کنی تا</h3>
                  </div>
                  <ul className="content__container__list">
                    <li
                      className="content__container__list__item me-1"
                      style={{ color: "#ffb01e" }}
                    >
                      <h3>خوش تیپ</h3>
                    </li>
                    <li
                      className="content__container__list__item me-1"
                      style={{ color: "#ff5251" }}
                    >
                      <h3 className="success-person">فرد موفق</h3>
                    </li>
                    <li
                      className="content__container__list__item me-1"
                      style={{ color: "#00a4ec" }}
                    >
                      <h3 className="slogan__fix_text_1">قهرمان</h3>
                    </li>
                    <li
                      className="content__container__list__item me-1"
                      style={{ color: "#8940ff" }}
                    >
                      <h3>خوش اندام</h3>
                    </li>
                  </ul>
                </div>
                <h3 style={{ color: "white" }}>باشی. غذات هر روز پشت دره</h3>
                <div id="box-show-packages">
                  <span id="text-box-show-packages">مشاهده پکیج های غذایی</span>
                  <i></i>
                </div>
              </div>
              <div 
                className="p-4 operation"
                style={{ background: "#353535", borderRadius: 20 }}
              >
                <input
                  type="checkbox"
                  defaultChecked={true}
                  id="compute"
                  onChange={changeCompute}
                  checked={showBmiForm}
                />

                <label id="motion-compute" >
                  <div onClick={changeCompute} id="radio-circle"></div>
                  <div  onClick={changeCompute} id="calories">محاسبه کالری</div>
                  <div  onClick={changeCompute} id="bmi">محاسبه BMI</div>
                </label>
                {showBmiForm && (
                  <div className="border d-grid bmi-form ">
                    <input
                      name="height"
                      onChange={(e) => setHeight(e.target.value)}
                      placeholder="قد (به سانتیمتر)"
                      className="form-control"
                      type="number"
                    />
                    <input
                      onChange={(e) => setWeight(e.target.value)}
                      name="weight"
                      className="form-control"
                      placeholder="وزن (به کیلوگرم)"
                      type="number"
                    />
                    <div className="p-3">
                      <span className="text-white w-100 text-center">
                        {bmi}
                      </span>
                    </div>
                    <button
                      onClick={runCompute}
                      className="w-100 mt-2 btn-compute"
                      value=" محاسبه کن"
                    >
                      محاسبه کن
                    </button>
                  </div>
                )}
                {!showBmiForm && (
                  <div className="border d-grid calories-form ">
                    <AutoComplete
                      options={recipes}
                      className="form-control"
                      onSearch={queryRecipe}
                      onSelect={(value, option) =>
                        queryRecipe(option.id, "units")
                      }
                      placeholder="انتخاب رسپی"
                    />
                    <select
                      placeholder="انتخاب واحد "
                      type="text"
                      className="form-control"
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      <option>انتخاب واحد</option>
                      {units.map((unit) => {
                        return (
                          <option style={{ color: "black" }} value={unit.id}>
                            {unit.value}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      type="number"
                      placeholder="درج مقدار"
                      className="form-control"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <div className="p-3">
                      <span className="text-white w-100 text-center">
                        {calories}
                      </span>
                    </div>
                    <button
                      onClick={runComputeCalories}
                      className="w-100 mt-2 btn-compute"
                      value=" محاسبه کن"
                    >
                      محاسبه کن
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Steps />
        
        <div className="diet mt-5">
          <div className="container-fluid p-0" style={{ height: 500 }}>
            {/* <!-- <div className="container" > --> */}

            <div className="download-box">
              <div
                style={{ display: "flex", height: 360 }}
                className="screenshots"
              >
                <img src="/images/m-2.png" alt="" className="w-100" />
                <img src="/images/m-1.png" alt="" className="w-100" />
              </div>
              <div className="description">
                <img
                  className="pt-2 ps-2"
                  src="/images/app-dowload-line.svg"
                  height="200vw"
                />
                <div className="download-app mt-5 mt-sm-0">
                  <h2 className="fw-bolder c-green">رژیم گرفتن خیلی سخته؟</h2>
                  <h4 className="p-3">هر چی دوس داری بخور،</h4>
                  <h4 className="text-start w-100">و سالم بمون</h4>
                  <br />
                  <button className="ps-5 pe-5 pt-2 pb-2 c-btn btn-success btn">
                    دانلود اپلیکیشن
                  </button>
                  <h5 className="text-center mt-3">
                    غذای خوشمزه بخور،
                    <br />و سالم بمون
                  </h5>
                </div>
              </div>

              <img
                height="410px"
                style={{ top: "-20%" }}
                className="position-absolute start-0 salad"
                src="/images/salad.png"
                alt=""
              />
            </div>
          </div>
          {/* <!-- </div> --> */}
        </div>
        <Packages />

      <Comments/>
      <Blogs />
        <div className="container mb-5 pb-5">
          <div className="define">
            <img src="/icon/logo.svg" />
            <p className="pe-4">
              هیلی میلی یک سبک زندگیه؛ همون سبک زندگی که ما رو عادت می‌ده
              چیزهایی رو دوست داشته باشیم که سبک زندگی سالم را برای ما به ارمغان
              بیاره.
            </p>
          </div>

          <div className="apps">
            <img src="/images/apple.svg" className="" />
            <img src="/images/google.svg" className="" />
          </div>
        </div>
      </>
    // </Master>
  );
};

export default Home;
