import storage from "../../services/storage";

export const loadJWTAction = () => {
    return async (dispatch) => {
      
        const jwt = await storage.loadJWT();
        await dispatch({ type: "JWT", payload: jwt })
    }
}
export const removeJWTAction = () => {
    return async (dispatch) => {
      
        const jwt = await storage.deleteJWT();
        await dispatch({ type: "DELETE_JWT", payload: jwt })
    }
}
export const saveJWTAction = (token) => {
    return async (dispatch) => {
      
        const jwt=await storage.saveJWT(token);
        await dispatch({ type: "JWT", payload: jwt })
    }
}