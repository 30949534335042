import React from 'react'
import { useContext } from 'react';
import { MdFavorite, MdOutlineFavoriteBorder } from 'react-icons/md'
import { useSelector } from "react-redux";
import UserApi from '../../../api/UserApi';
import MainContext from '../../../context/MainContext';
const ItemFavorite = (props) => {
    const context=useContext(MainContext)
    const jwt=useSelector(state=>state.jwt)
    const removeFavorite=async()=>{
        try{
        const _res=await UserApi.taggoleFavorite(props.food.id,jwt)
        props.delete(props.food)
        }catch(e){
            context.handleMsg(e,"error")
        }
    }
  return (
    <div className="food  d-grid position-relative">
      <div className="img w-100">
        {" "}
        <img src={props.food.cover} alt="" className="w-100" />
      </div>
      <div className="calories">
        {!props?.disabled &&
          props?.food?.values.map((value, index) => (
            <div className="d-grid" key={index}>
              <span>{value.attribute}</span>
              <h6 dir="ltr">
                {" "}
                {value.value} {value.unit}
              </h6>
            </div>
          ))}
      </div>
      <div className="info p-3">
        <div className=" d-f-j-a">
          <h6>{props.food.name}</h6>
          <MdFavorite size={24} role="button" onClick={removeFavorite} color="red" className='mb-2'/>
        </div>
        <h6 className="w-100 float-end text-start mt-3">
          {props.food.price} <small className="fw-normal">تومان</small>
        </h6>
       
      </div>
    </div>
  )
}

export default ItemFavorite