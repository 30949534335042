import React, { useContext, useEffect, useState } from "react";
import { BsHouseDoor } from "react-icons/bs";
import { MdOutlineTipsAndUpdates,MdOutlineDeliveryDining } from "react-icons/md";
import { BiLocationPlus, BiTimeFive } from "react-icons/bi";
import { useSelector } from "react-redux";
import CartApi from "../../../api/CartApi";
import MainContext from "../../../context/MainContext";
import DaySelect from "./DaySelect";
import ModalAddress from "../../../modals/ModalAddress";
import AddressApi from "../../../api/AddressApi";
import { Radio } from "antd";
const Step2 = () => {
  const context = useContext(MainContext);
  const [boxAddress, setBoxAddress] = useState(false)
  const jwt = useSelector((state) => state.jwt);
  const [addresses, setAddresses] = useState([]);
  const [info, setInfo] = useState(null);
  const [detail, setDetail] = useState([])
  const init = async () => {
    try {
      let _res = await CartApi.listPacket(jwt);
      setInfo(_res);
      console.log(_res, "list packet");
      _res = await AddressApi.loadAddresses(jwt);
      setAddresses(_res);
      console.log(_res, "list address");
      _res = await CartApi.detailPacket(jwt);
      setDetail(_res);
    } catch (error) {
      context.handleMsg(error);
    }
  };
  useEffect(() => {
    if (jwt) init();
  }, [jwt]);
  const toggleBoxAddress=()=>{
    setBoxAddress(!boxAddress)
  }
  const onChangeAddress=(value)=>{

  }
  const onUpdate=()=>{
    init();
  }
  return (
    <div className="step2 mt-3">
      <div>
        <h6 className="d-f-j-a">
          <span>
            {" "}
            <BsHouseDoor /> آدرس تحویل سفارش{" "}
          </span>
          <span className="c-green" role={"button"} onClick={toggleBoxAddress}>
            {" "}
            <BiLocationPlus /> افزودن آدرس جدید{" "}
          </span>
        </h6>
        <div className="d-flex pt-2 mt-2" style={{borderTop:1,borderTopStyle:"solid",borderColor:"#d5d5d5",borderTopWidth:1}}>
      <Radio.Group onChange={onChangeAddress} >

      { addresses.map((item,index)=>{
        return (
         
        
          <div key={index}>
          <Radio value={item.id}>{item.name}<br/>{item.address}</Radio>
 
          </div>
        )
      })

      }
          </Radio.Group>

      </div>
      </div>

      <hr />
      <div>
        <h6 className="d-f-j-a">
          <span>
            <BiTimeFive /> زمان تحویل سفارش{" "}
          </span>
        </h6>
        <p className="c-green">
          <MdOutlineTipsAndUpdates />  وعده های انتخابی شما در هر روز، یه صورت
          پکیج در ساعت انتخابی شما ارسال خواهد شد.{" "}
        </p>
      </div>
      <div>
        {info?.map((item,index) => {
          return <div key={index}>
            <DaySelect data={item}  />
          </div>;
        })}
      </div>
      <hr />
      {/* <h6 className="d-f-j-a">
        <span>
          <MdOutlineDeliveryDining />جمع هزینه ارسال
        </span>
      </h6> */}
       <ModalAddress onUpdate={onUpdate}  showModal={boxAddress}  handleCancel={toggleBoxAddress} />
    </div>
  );
};

export default Step2;
