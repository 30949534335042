import React, { Fragment, useEffect, useState } from "react";

const Timer = (props) => {
  const checkRemaining = ({expire_time,remaining_time}) => {

    if (Number(remaining_time) == 0)
      return {
        initialMinute:Math.floor( Number(expire_time) / 60),
        initialSeconds: Math.floor(Number(expire_time)  % 60),
      };
      
    return {
       
      initialMinute: Math.floor(Number(remaining_time) / 60),
      initialSeconds:Math.floor(Number(remaining_time)  % 60),
    };
  };
 
  const { initialMinute = 2, initialSeconds =30} = checkRemaining(props.data);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });
  const reset=()=>{
    props.requestOTP()
    setMinutes(2)
    setSeconds(0)
  }
  return (
    <Fragment>
      {minutes === 0 && seconds === 0 ? 
      <a className="text-decoration-underline " onClick={reset}>ارسال مجدد</a>
      :  
          
            "ارسال مجدد پس از  "+minutes+":" +seconds
      }
      </Fragment>
  );
};

export default Timer;
