import { Alert } from "antd";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Master from "../../layouts/Master";
import Panel from "../../layouts/Panel";

const Payment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {}, []);
  return (
    <Panel>
      {searchParams.get("payment_status") === "Complete" ? (
        <Alert
          type="success"
          message="پرداخت موفقیت آمیز"
          description={
            <div>
              <p>پرداخت شما به درستی انجام شده و سفارش شما ثبت شده است</p>
              <p>
                در اولین فرصت کارشناسان ما پیگیری کرده و در صورت لزوم با شما
                تماس گرفته میشود یا پیامی دریافت خواهید کرد.
              </p>
              <span>
                شما میتوانید در داشبورد از وضعیت سفارش خود با خبر شوید
              </span>
              <br />
              <div>
                <div>
                  <span className="fw-bold">کد پیگیری: </span>
                  <span> {searchParams.get("tracking_code")}</span>
                </div>
                <div>
                  <span className="fw-bold">مبلغ پرداختی: </span>
                  <span> {searchParams.get("amount")} ریال</span>
                </div>
                <div>
                  <span className="fw-bold">تاریخ و ساعت:  </span>
                  <span> {searchParams.get("date")}</span>
                </div>
              </div>
            </div>
          }
        />
      ) : (
        <Alert
          type="error"
          message="خطا در پرداخت"
          description="پرداخت شما نا موفق بوده. سفارش شما در بخش لیست سفارشات ثبت شده، میتوانید برای پرداخت بعدا اقدام نمایید.
بعد از پرداخت موفق سفارش شما پیگیری میشود."
        />
      )}
    </Panel>
  );
};

export default Payment;
