import Global from "../config.json";
import httpService from "../services/httpService";
const BasketApi = {
  async loadBasket( jwt) {
    let resStatus = 0;
    let res = [];

    await httpService
      .get(
        `${Global.HealyMealy}cart/?detail=true`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "load card");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async getBasketFoods(pid, jwt) {
    let resStatus = 0;
    let res = [];

    await httpService
      .get(
        `${Global.HealyMealy}cart/${pid}`,
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async addtoBasketFoods(oid,mid, items, jwt) {
    let resStatus = 0;
    let res = [];

    await httpService
      .post(
        `${Global.HealyMealy}cart/${oid}/order/`,
        JSON.stringify({
          items: items,
          meal:mid
        }),
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "add food");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async updateBasketFoods(pid, fid, jwt) {
    let resStatus = 0;
    let res = [];
    await httpService
      .put(
        `${Global.HealyMealy}shop/${pid}/order/`,
        JSON.stringify({
          recipe: fid,
        }),
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async loadActivePackage( jwt) {
    let res = [];

    await httpService
      .get(
        `${Global.HealyMealy}payment/activate-package/`,
      
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "load payment");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async activePackage( jwt) {
    let res = [];

    await httpService
      .post(
        `${Global.HealyMealy}payment/activate-package/`,
      {},
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "active payment");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
  async doPayment( jwt) {
    let res = [];

    await httpService
      .post(
        `${Global.HealyMealy}payment/`,
      {},
        {
          headers: jwt,
        }
      )
      .then((json) => {
        console.log(json, "do payment");
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        }
      });

    return res;
  },
};
export default BasketApi;
