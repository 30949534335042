import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="contacts">
          <div className="item">
            <img
              src="/icon/Subscribe.svg"
              className="mb-3"
              height="90px"
              // onclick="loadContext(this)"
            />
            <div className="box p-4 bg-white rounded-3">
              <label htmlFor="subscribe " className="mb-2">
                در خبرنامه هیلی میلی عضو شوید و زودتر از همه تخفیف های ویژه ما
                با خبر شوید
              </label>

              <input
                className="form-control"
                type="text"
                placeholder="ایمیل"
                name="subscribe"
                id="subscribe"
              />
            </div>
          </div>
          <div className="item" >
            <img
              src="/icon/Social_media.svg"
              className="mb-3"
              height="90px"
              // onclick="loadContext(this)"
            />
            <div className="box p-4 bg-white rounded-3">
              <label htmlFor="subscribe " className="mb-2 w-100">
                به ما بپیوندید!
                <br />
                ما را در صفحات اجتماعی دنبال کنید
              </label>

              <div className="d-f-j-a mb-3">
                <a href="#">
                  <i className="linkedin"></i>
                </a>
                <a href="#">
                  <i className="insta"></i>
                </a>
                <a href="#">
                  <i className="telegram"></i>
                </a>
                <a href="#">
                  <i className="whatsapp"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="item">
            <img
              src="/icon/contact.svg"
              className="mb-3"
              height="90px"
              // onclick="loadContext(this)"
            />
            <div className="box p-4 pb-2 bg-white rounded-3">
              <label htmlFor="subscribe " className="mb-1">
                از این راه ها میتونی با ما در ارتباط باشی
                <br />
                منتظر انتقاد و پیشنهادت هستیم
              </label>

              <div className="contact">
                <div className="d-flex align-items-center">
                  <img className="rounded-0" src="/icon/email.svg" />
                  <p className="m-0">@healymealy</p>
                </div>
                <div className="d-flex align-items-center">
                  <img className="rounded-0" src="/icon/tell.svg" />
                  <p className="m-0">021-77036895</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
