import { Carousel } from "antd";
import React from "react";
const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "0px",
  slidesToShow: 3,
  speed: 500,
  className:"persons m-0",
  responsive: [
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        }
    },
  ]
};
const Consultants = () => {
  return (

      <Carousel  {...settings}>
    
        <div className="person" style={{width:"95%"}}>
          <svg height="250" width="250" className="back-circle">
            <circle
              cx="150"
              cy="150"
              r="140"
              stroke="black"
              strokeWidth="0"
              fill="#D4FFEA"
            />
          </svg>
          <img src="/images/trash/p1.png" className="img-fluid" alt="" />
          <div className="name">
            <h6>مهناز افشار</h6>
            <p>کارشناس تغذیه و رژیم</p>
          </div>
        </div>
        <div className="person">
          <svg height="250" width="250" className="back-circle">
            <circle
              cx="150"
              cy="150"
              r="140"
              stroke="black"
              strokeWidth="0"
              fill="#D4FFEA"
            />
          </svg>
          <img src="/images/trash/p1.png" className="img-fluid" alt="" />
          <div className="name">
            <h6>مهناز افشار</h6>
            <p>کارشناس تغذیه و رژیم</p>
          </div>
        </div>
        <div className="person">
          <svg height="250" width="250" className="back-circle">
            <circle
              cx="150"
              cy="150"
              r="140"
              stroke="black"
              strokeWidth="0"
              fill="#D4FFEA"
            />
          </svg>
          <img src="/images/trash/p1.png" className="img-fluid" alt="" />
          <div className="name">
            <h6>مهناز افشار</h6>
            <p>کارشناس تغذیه و رژیم</p>
          </div>
        </div>
      </Carousel>

  );
};

export default Consultants;
