import React, { useState } from "react";
import { Modal, Select, Button } from "antd";
import { BiRightArrowAlt } from "react-icons/bi";
import Global from "../config.json";

import { CgSearch } from "react-icons/cg";
import ModalAddressInfo from "./ModalAddressInfo";
import Mapir from "mapir-react-component";
import "mapir-react-component/dist/index.css";
import AddressApi from "../api/AddressApi";
const Map = Mapir.setToken({
  transformRequest: (url) => {
    return {
      url: url,
      headers: {
        "x-api-key":
        `${Global.MAPKEY}`,

        "Mapir-SDK": "reactjs",
      },
    };
  },
});

const ModalMap = (props) => {
  const [boxAddressInfo, setBoxAddressInfo] = useState(false);
  const [lng, setLng] = useState(51.42047);
  const [lat, setLat] = useState(35.729054);
  const [info, setInfo] = useState({});
  const [queries, setQueries] = useState([]);
  const [markerArray, setMarkerArray] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);
  let timeout;
  let currentValue;

  const toggleBoxInfo = () => {
    console.log(!boxAddressInfo)
    setBoxAddressInfo(!boxAddressInfo);
  };
  const reverseFunction = async (map, e) => {
    try {
      const _res = await AddressApi.reverseAddress(e.lngLat.lat, e.lngLat.lng);
      setInfo(_res);

      const array = [];
      array.push(
        <Mapir.Marker
          coordinates={[e.lngLat.lng, e.lngLat.lat]}
          anchor="bottom"
        ></Mapir.Marker>
      );
      setLng(e.lngLat.lng);
      setLat(e.lngLat.lat);
      setMarkerArray(array);
      console.log(array, lng, lat);
    } catch (e) {}
  };
  const fetch = async (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    const loadData = async () => {
      try {
        const res = await AddressApi.searchAddress(value);
        console.log(res);
        setQueries(res);
        const _data = res.value.map((item, index) => ({
          value: index,
          text: item.address,
        }));
        callback(_data);
      } catch (e) {
        console.log(e, 22222);
      }
    };

    timeout = setTimeout(loadData, 300);
  };

  const handleSearch = (newValue) => {
    if (newValue) {
      fetch(newValue, setData);
    } else {
      setData([]);
    }
  };

  const handleChange = (newValue) => {
    setValue(newValue);
    console.log(queries, newValue);
    const _lat = queries.value[Number(newValue)].geom.coordinates[1];
    const _lon = queries.value[Number(newValue)].geom.coordinates[0];
    const _info = queries.value[Number(newValue)];
    console.log(_lat, _lon, 77777777777);
    const array = [];
    array.push(
      <Mapir.Marker coordinates={[_lon, _lat]} anchor="bottom"></Mapir.Marker>
    );
    setInfo(_info);
    setLng(_lon);
    setLat(_lat);
    setMarkerArray(array);
  };

  const options = data?.map((d) => (
    <Select.Option key={d.value}>{d.text}</Select.Option>
  ));
const onClose= ()=>{
   toggleBoxInfo()
  setTimeout(props.handleCancel(),2000)
  
}
  return (
    <Modal
      title={
        <span>
          <BiRightArrowAlt
            role={"button"}
            onClick={() => {
              props.handleCancel();
            }}
            size={24}
          />{" "}
          موقعیت مکانی جدید را مشخص کنید.
        </span>
      }
      visible={props.showModal}
      footer={false}
      onCancel={() => props.handleCancel()}
    >
      <div>
        {/* <Input
          className="form-control border rounded-3 mb-3"
          placeholder="جستجو در آدرس"
          onChange={onQuery}
        /> */}
        <Select
          showSearch
          suffixIcon={<CgSearch  size={19}/>}
          value={value}
          placeholder="جستجو در آدرس"
          className="form-control border rounded-3 mb-3"
          defaultActiveFirstOption={false}
          // showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
        >
          {options}
        </Select>
      </div>
      <div className="w-100 mt-1">
        <Mapir
          center={[lng, lat]}
          minZoom={[13]}
          // scrollZoom={false}
          hash={true}
          onClick={reverseFunction}
          Map={Map}
          interactive={true}
        >
          {markerArray}
        </Mapir>
      </div>
      <div>
        <Button
          onClick={toggleBoxInfo}
          type="primary"
          shape="round"
          className="  w-50 mx-auto mt-2 mb-4 c-btn btn-success border border-0"
        >
          تایید و ادامه
        </Button>
      </div>
      <ModalAddressInfo
        showModal={boxAddressInfo}
        onSuccess={onClose}
        data={{ lat: lat, lng: lng, info: info }}
        handleCancel={toggleBoxInfo}
      />
    </Modal>
  );
};

export default ModalMap;
