import Global from "../config.json";
import httpService from "../services/httpService";
const AddressApi={
    async loadAddresses(jwt) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}address/`,{
            headers:jwt
          })
          .then((json) => {
              console.log("load addresses",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
        async searchAddress(q) {
            let res = [];
            await httpService
              .post(`${Global.MAPURL}search/v2/autocomplete/`,{text:q},{
                headers:{
                  "x-api-key":`${Global.MAPKEY}`,
                  "accept": "application/json"
                }
              })
              .then((json) => {
                  console.log("load query addresses",json)
                switch (json.status) {
                  case 201:
                  case 200:
                    res = json.data
                    break;
                }
              })
            return res;
          },
        
          async reverseAddress(lat,lng) {
            let res = [];
            await httpService
              .get(`${Global.MAPURL}reverse/no?lat=${lat}&lon=${lng}`,{
                headers:{
                  "x-api-key":`${Global.MAPKEY}`,
                  "accept": "application/json"
                }
              })
              .then((json) => {
                  console.log("load name address",json)
                switch (json.status) {
                  case 201:
                  case 200:
                    res = json.data
                    break;
                }
              })
            return res;
          },
          async insertAddress(data,jwt) {
            let res = [];
            await httpService
              .post(`${Global.HealyMealy}address/`,data,{
                headers:jwt
              })
              .then((json) => {
                  console.log("insert  addresse",json)
                switch (json.status) {
                  case 201:
                  case 200:
                    res = json.data
                    break;
                }
              })
            return res;
          },
        
}
 export default AddressApi;