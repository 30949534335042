import { Input, InputNumber, Select } from "antd";
import React, { forwardRef, useContext, useState } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import QuestionsAPI from "../../../../../api/QuestionsAPI";
import QuestionContext from "../../../../../context/QuestionContext";
import InputType from "../../tools/InputType";
import { useSelector } from "react-redux";

const Step1 = forwardRef((props,ref) => {
  const questionContext=useContext(QuestionContext)
  const jwt=useSelector(state=>state.jwt)
  const preprocessData=async ()=>{
    let _answers=[]
    questionContext.questions["information"].map((item,index)=>{
      console.log(item.newAnswer,11111)
      if(item?.newAnswer) questionContext.setProcessStep1(index,25)
      else questionContext.setProcessStep1(index,0)
      console.log(item.newAnswer,22222)

      _answers.push({question:item.id,answer:{answer_type:item.answer_type,answer:item.newAnswer??null}})
    })
    return _answers
  }
  useImperativeHandle(ref,()=>({
    async onSubmit(){
      const _data=await preprocessData()
      console.log(_data)
     const _res=await QuestionsAPI.sendStepsData(_data,jwt)
  }
}))
    
  return (
    <div className="d-f-j-a">


    {questionContext?.questions["information"]?.map((item,index)=>{
      return(
        <div className="d-grid" key={index}>
        <label>{item.text}</label>
        <InputType data={item}  questions={questionContext?.questions["information"]}/>
      </div>
      )

    })
    }




    </div>
  );
});

export default Step1;
