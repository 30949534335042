import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
} from "react";
import QuestionContext from "../../../../../context/QuestionContext";
import InputType from "../../tools/InputType";
import { useSelector } from "react-redux";
import QuestionsAPI from "../../../../../api/QuestionsAPI";
import { Button, Modal } from "antd";
import { BiCheckCircle } from "react-icons/bi";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import UploadFiles from "../../../../../commons/UploadFiles";
import { useNavigate } from "react-router-dom";

const Step5 = forwardRef((props, ref) => {
  const questionContext = useContext(QuestionContext);
  const navigate=useNavigate()
  const [, forceUpdate] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUploadVisible, setIsModalUploadVisible] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const preprocessData = async () => {
    let _answers = [];
    questionContext?.questions["food_pattern"]?.map((item, index) => {
      if (item?.newAnswer) questionContext.setProcessStep5(index, 5);
      else questionContext.setProcessStep5(index, 0);
      _answers.push({
        question: item.id,
        answer: { answer_type: item.answer_type, answer: item.newAnswer },
      });
    });
    return _answers;
  };
  useImperativeHandle(ref, () => ({
    async onSubmit() {
      const _data = await preprocessData();
      console.log(_data);
      const _res = await QuestionsAPI.sendStepsData(_data, jwt);
      setIsModalVisible(true);
    },
  }));
  const onUpdate = () => {
    forceUpdate(Math.random());
  };
  const onCancelUploadBox=()=>{
    setIsModalUploadVisible(false)
    navigate("/panel/consultant")
  }
  const onSaveFiles=(files)=>{

    setIsModalUploadVisible(false)
    navigate("/panel/consultant")
  }
  const gotIt=()=>{
   setIsModalVisible(false)
   setIsModalUploadVisible(true)
  }
  return (
    <>
      {questionContext?.questions["food_pattern"]?.map((item, index) => {
        return (
          <div className="d-grid mt-3" key={index}>
            <label>{item.text}</label>
            <InputType
              data={item}
              questions={questionContext?.questions["food_pattern"]}
              onUpdate={onUpdate}
            />
          </div>
        );
      })}
        <Modal
        title=""
        visible={isModalUploadVisible}
        closable
        onCancel={() => setIsModalUploadVisible(false)}
        footer={false}
      >
        <UploadFiles cancel={onCancelUploadBox} saveFiles={onSaveFiles} />
       
      </Modal>
      <Modal
        title=""
        visible={isModalVisible}
        closable
        onCancel={() => setIsModalVisible(false)}
        footer={false}
      >
        <div className="d-grid justify-contents-center text-center m-auto">
          <BiCheckCircle className="w-100 mt-3 mb-2" size={32} color="green" />
          <h6>تکمیل پرسشنامه</h6>
          <p>
            شما موفق به تکمیل پرسشنامه شده اید. کارشناسان هیلی میلی بعد از بررسی
            جوابها، با شما تماس می گیرند. از صبر و شکیبایی شما سپاسگذاریم.{" "}
          </p>
          <Button shape="round" className="c-btn btn-success p-3" onClick={gotIt}>
            متوجه شدم
          </Button>
        </div>
      </Modal>
    </>
  );
});

export default Step5;
