import React from 'react';
import {hydrateRoot ,createRoot} from 'react-dom/client';
import 'antd/dist/antd.min.css'
import App from './App';
import reportWebVitals from "./reportWebVitals";

const root = createRoot(document.getElementById("root"));

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrateRoot(<App />, rootElement);
// } else {
  root.render(
    <React.StrictMode>
  <App />
  </React.StrictMode>);
  
// }
reportWebVitals();