import React, { useState, useEffect, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import MainContext from "./context/MainContext";

import Home from "./pages/home/Home";
import Dashboard from "./pages/panel/home/Home";
import handleMsg from "./tools/handleMsg";
import Pack from "./pages/package/Pack.jsx";
import Order from "./pages/order/Order";
import Register from "./modals/Register";
import { useDispatch, useSelector } from "react-redux";
import { loadJWTAction } from "./redux/actions/jwtActions";
import Foods from "./pages/foods/Foods";
import Basket from "./pages/basket/Basket";
import Orders from "./pages/panel/orders/Orders";
import List from "./pages/panel/List";
import Consultant from "./pages/panel/Consultant";
import FillForm from "./pages/panel/consultant/FillForm";
import Login from "./commons/Login";
import About from "./pages/about/About";
import Profile from "./pages/panel/Profile";
import ListConsultants from "./pages/panel/consultant/ListConsultants";
import Payment from "./pages/basket/Payment";
import useWindowDimensions from "./tools/handleResize";
import ScrolltoTop from "./pages/panel/consultant/tools/ScrolltoTop";
import { BiHome } from "react-icons/bi";
import Master from "./layouts/Master";
import Sidebar from "./layouts/Sidebar";
import Panel from "./layouts/Panel";
const HealyMealy = (props) => {
  const [showLogin, setShowLogin] = useState(false);

  const dispatch = useDispatch();
  const [, forceUpdate] = useState(0);
  const jwt = useSelector((state) => state.jwt);
  const registerModal = useRef();
  const checkLogin = () => {
    if (jwt) return registerModal.current?.show(false);
    return registerModal.current?.show(true);
  };
  useEffect(() => {
    dispatch(loadJWTAction());
  }, []);

  const requireAuth = () => {
    if (jwt === "") return true;
  };
  const refresh = () => {
    forceUpdate(Math.random());
  };
  const scrollToEl = (el) => {
    let _el = document.getElementById(el);
    _el &&
      _el.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };
  return (
    <MainContext.Provider
      value={{
        handleMsg: handleMsg.showMsg,
        checkLogin: checkLogin,
        checkWindow: useWindowDimensions,
        refresh: refresh,
        scrollToEl:scrollToEl
      }}
    >
      <Register ref={registerModal} />

      <ScrolltoTop>
        <Routes>
          <Route path="/" element={<Master />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route
              path="consultants"
              element={requireAuth() ? <Login /> : <ListConsultants />}
            />
            <Route
              path="pack/:pid/order/"
              exact
              element={requireAuth() ? <Login /> : <Order />}
            />
            <Route
              path="pack/:pid/order/:oid/foods/:mid"
              exact
              element={requireAuth() ? <Login /> : <Foods />}
            />
            <Route path="/pack/:pid" exact element={<Pack />} />
            <Route
              path="basket/:pid"
              exact
              element={requireAuth() ? <Login /> : <Basket />}
            />
            <Route
              path="basket/payment"
              exact
              element={requireAuth() ? <Login /> : <Payment />}
            />

            <Route
              path="panel"
              element={requireAuth() ? <Login /> : <Panel />}
            >
              <Route
                index
                element={requireAuth() ? <Login /> : <Dashboard />}
              />

              <Route
                path="orders"
                exact
                element={requireAuth() ? <Login /> : <Orders />}
              />
              <Route
                path="profile"
                exact
                element={requireAuth() ? <Login /> : <Profile />}
              />

              <Route
                path="list"
                exact
                element={requireAuth() ? <Login /> : <List />}
              />

              <Route
                path="consultant/fill-form"
                exact
                element={requireAuth() ? <Login /> : <FillForm />}
              />

              <Route
                path="consultant"
                exact
                element={requireAuth() ? <Login /> : <Consultant />}
              />
            </Route>
          </Route>
        </Routes>
      </ScrolltoTop>
    </MainContext.Provider>
  );
};
export default HealyMealy;
