import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ModifyH from "../../../commons/ModifyH";

const Basket = (props) => {
  const card=useSelector(state=>state.card)
  useEffect(()=>{
    // console.log(card,6666666)
  })
  const checkTotal=()=>{
    let _total=0
    card.map(meal => meal.product.map(recipe=>{
      _total+=Number(recipe.number??0)
    }))
    return _total
  }
  return (
    <div className="w-100">
      <strong>تعداد آیتم های انتخابی در این روز:{checkTotal()}</strong>
      <ul className="list-group">
        { card.map((meal,index)=>
        meal.product.map((item,index)=>{ 
          item.product.number=item.number;
         return (
            <li className="list-group-item" key={index}>
             <p>{item.product.name}</p>
             <div>
               <strong>{item.product.price}</strong>  تومان
               <ModifyH food={item.product} />
             </div>
            </li>

          )})
        )
         }
      </ul>
    </div>
  );
};

export default Basket;
