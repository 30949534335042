import React, { forwardRef, useContext, useImperativeHandle, useState } from "react";
import QuestionsAPI from "../../../../../api/QuestionsAPI";
import QuestionContext from "../../../../../context/QuestionContext";
import InputType from "../../tools/InputType";
import { useSelector } from "react-redux";

const Step4= forwardRef((props,ref) => {
  const questionContext = useContext(QuestionContext);
  const jwt=useSelector(state=>state.jwt)
  const [, forceUpdate] = useState(0);
  const onUpdate = () => {
    forceUpdate(Math.random());
  };
  const preprocessData=async ()=>{
    let _answers=[]
    questionContext?.questions["clinical_assessment"]?.map((item,index)=>{
      if(item?.newAnswer) questionContext.setProcessStep4(index,9)
      else questionContext.setProcessStep4(index,0)
      _answers.push({question:item.id,answer:{answer_type:item.answer_type,answer:item.newAnswer}})
    })
    return _answers
  }
  useImperativeHandle(ref,()=>({
    async onSubmit(){
      const _data=await preprocessData()
      console.log(_data)
     const _res=await QuestionsAPI.sendStepsData(_data,jwt)
  }
}))
  return (
    <>
   
      {questionContext?.questions["clinical_assessment"]?.map((item, index) => {
          return (
            <div className="d-grid mt-3" key={index}>
              <label>{item.text}</label>
              <InputType data={item}  questions={questionContext?.questions["clinical_assessment"]} onUpdate={onUpdate} />
            </div>
          );
      })}
    </>
  );
});

export default Step4;
