import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import {
  addBasketAction,
  removeBasketAction,
  updateBasketAction,
} from "../redux/actions/basketActions";
import { store } from "../redux/store";
const ModifyH = (props) => {

  const params = useParams();
  const context = useContext(MainContext);
  const [orderId, setOrderId] = useState(null)
  const [count, setCount] = useState(0);
  const jwt=useSelector(state=>state.jwt)
  const packages=useSelector(state=>state.packages)
  const dispatch = useDispatch();
  const addToBasket = () => {
    if(props?.disabled)
    return
    props.food.pid = params.pid;
    props.food.mid = params.mid;
    props.food.oid = params.oid??orderId;
    // if(count==0)
    dispatch(addBasketAction(props.food,context,jwt));
    // else
    // dispatch(updateBasketAction(props.food,count+1,context,jwt));

    setCount(count + 1);
    
  };
  const removeFromBasket = () => {
    if(props?.disabled)
    return
    if (count <= 0) return;
    props.food.pid = params.pid;
    props.food.mid = params.mid;
    props.food.oid = params.oid??orderId;;
    dispatch(removeBasketAction(props.food,context,jwt));
    setCount(count - 1);
  };
  useEffect(()=>{
    if(packages)
    setOrderId(packages[0].id)
  },[packages])
  useEffect(()=>{
    setCount(props?.food?.number??0)
  })
  return (
    <div
      className="d-f-j-a p-1  text-center bg-white w-50 float-start   border border-1 rounded-3  "
      style={{ width: 30 }}
    >
      <CgMathPlus className="c-orange " color= {props?.disabled?"gray":null}  role={"button"} onClick={addToBasket} />
      <span >{count}</span>
      <CgMathMinus color= {props?.disabled?"gray":null} 
        className="c-orange"
        onClick={removeFromBasket}
        role={"button"}
      />
    </div>
  );
};

export default ModifyH;
