import { Button, Radio, Space } from "antd";
import React from "react";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlinePayment,
} from "react-icons/md";
import { FaHandHoldingUsd } from "react-icons/fa";
const Step3 = () => {
  return (
    <div>
      <div className="mt-3">
        <h6 className="d-f-j-a">
          <span>انتخاب روش پرداخت</span>
        </h6>
      </div>
      <div>
        <Radio.Group value={1}>
          <Space direction="vertical">
            <Radio value={1}>
              {" "}
              <MdOutlinePayment size={20} /> پرداخت اینترنتی
            </Radio>
            <Radio disabled value={2}>
              {" "}
              <FaHandHoldingUsd size={20} /> در محل (کارت بانکی){" "}
            </Radio>
            <Radio disabled value={3}>
              {" "}
              <MdOutlineAccountBalanceWallet size={20} /> اعتباری
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <hr />
      <div className="mt-3">
        <h6 className="d-f-j-a">
          <span>اعمال کد تخفیف</span>
        </h6>
      </div>
      <div className="d-flex">
        <input className="form-control w-50" placeholder="کد تخفیف را وارد کنید"/>
        <button   className="b-green c-green me-3 rounded-3 bg-white border border-1 p-2 ps-4 pe-4" >
        بررسی کد
        </button>
      </div>
      <hr />
      <div className="mt-3">
        <h6 className="d-f-j-a">
          <span>اگر توضیحاتی در مورد سفارش خود دارید، در این قسمت وارد کنید.</span>
        </h6>
      </div>
      <div>
      <textarea rows={4} className="form-control " placeholder="توضیحات ..."></textarea>

      </div>
    </div>
  );
};

export default Step3;
