import React, { useState } from "react";
import { Modal, Input, Button, Select, InputNumber } from "antd";
import { BiRightArrowAlt } from "react-icons/bi";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { useContext } from "react";
import MainContext from "../context/MainContext";
import AddressApi from "../api/AddressApi";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const ModalAddressInfo = (props) => {
  const jwt = useSelector((state) => state.jwt);
  const [address, setAddress] = useState(props.data?.info?.address);
  const [unit, setUnit] = useState("");
  const [number, setNumber] = useState("");
  const [boxAddressInfo, setBoxAddressInfo] = useState(false)
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [post, setPost] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const context = useContext(MainContext);
  useEffect(() => {
    setAddress(props.data?.info?.address);
  }, [props.data]);
  const preparingData = () => {
    return {
      lat: props.data.lat,
      lng: props.data.lng,
      postal_code: post,
      address: address,
      name: name,
      unit: unit,
      number: number,
      city: 1,
      state: 8,
    };
  };
  const checkValidation = () => {
    if (post.toString().length >= 1 && post.toString().length !== 10) {
      setError("کد پستی ده رقم می باشد");
      return true;
    }
    if (!unit) {
      setError("واحد را مشخص کنید");
      return true;
    }
    if (!name) {
      setError("نام آدرس را مشخص کنید");
      return true;
    }
    if (!number) {
      setError("پلاک  را مشخص کنید");
      return true;
    }
    return false;
  };
  const submit = async () => {
    try {
      if (checkValidation()) {
        return;
      }
      setIsLoading(true)
      setError("");
      const _data = preparingData();
      const _res = await AddressApi.insertAddress(_data, jwt);
      
      props.onSuccess();

    } catch (e) {
      context.handleMsg(e, "error");
    }
    finally{
      setIsLoading(false)
    }
  };
  return (
    <Modal
      title={
        <span>
          <BiRightArrowAlt
            size={24}
            role="button"
            onClick={() => {
              props.handleCancel();
            }}
          />{" "}
          جزئیات آدرس جدید
        </span>
      }
      visible={props.showModal}
      footer={false}
      onCancel={() => props.handleCancel()}
    >
      <div className="mb-2 d-grid w-100 ms-2">
          <label>نام آدرس</label>
          <Input  className="w-100" placeholder="مثال: خانه" value={name} onChange={(e)=>setName(e.target.value)} />
      </div>
      <div>
        <Input.TextArea
          rows={4}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        ></Input.TextArea>
        <div className="mt-2" style={{color: "#198754"}}>
          <MdOutlineTipsAndUpdates color="#198754" size={24} /> آدرس بالا بر اساس موقعیت انتخابی
          شما وارد شده است.
        </div>
      </div>
      <div className="d-f-j-a">
        <div className="d-grid w-100 ms-2">
          <label>استان</label>
          <Select defaultValue="تهران" disabled>
            <Select.Option value="تهران">تهران</Select.Option>
          </Select>
        </div>
        <div className="d-grid w-100">
          <label>شهر</label>
          <Select defaultValue="تهران" disabled>
            <Select.Option value="تهران">تهران</Select.Option>
          </Select>
        </div>
      </div>
      <div className="d-f-j-a w-100 mt-2">
        <div className="d-f-j-a w-100 ms-2">
          <div className="d-grid w-100  ms-2">
            <label>پلاک</label>
            <InputNumber
              value={number}
              onChange={setNumber}
              className="w-100"
            />
          </div>
          <div className="d-grid w-100 ">
            <label>واحد </label>
            <InputNumber value={unit} onChange={setUnit} className="w-100" />
          </div>
        </div>
        <div className="d-grid w-100">
          <label>کد پستی</label>
          <InputNumber
            value={post}
            maxLength={10}
            minLength={10}
            onChange={setPost}
            className="w-100"
          />
        </div>
      </div>
      {error && (
        <div class="alert alert-danger mt-3" role="alert">
          {error}
        </div>
      )}
      <div>
        <Button
          onClick={submit}
          type="primary"
          loading={isLoading}
          shape="round"
          className="  w-50 mx-auto mt-2 mb-4 c-btn btn-success border border-0"
        >
          ثبت آدرس
        </Button>
      </div>
    </Modal>
  );
};

export default ModalAddressInfo;
