import { Button, Select } from "antd";
import React, { useState } from "react";
import { useContext } from "react";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import MainContext from "../../../../context/MainContext";
const SelectDays = () => {
  const navigate = useNavigate();
  const [day, setDay] = useState(0);
  const [error, setError] = useState("gray");
  const context = useContext(MainContext);
  const checkSelectDay = () => {
    if (day !== 0) {
      setError("gray");

      navigate(`/panel/consultant/fill-form`);
    } else {
      setError("red");
      context.handleMsg("ابتدا روز را مشخص کنید", "error");
    }
  };
  const onChangeSelectDay = (value) => {
    setDay(value);
  };
  return (
    <div className="selectDays border border-1 rounded-3 p-3 pb-0 mt-3 d-f-j-a align-items-start">
      <Select
        style={{ width: 220, borderColor: `${error}` }}
        bordered={false}
        onChange={onChangeSelectDay}
        placeholder={"تعداد روز حمایت مشاور تغذیه"}
      >
        <Select.Option value="7">یک هفته</Select.Option>
        <Select.Option value="14">دو هفته</Select.Option>
      </Select>
      <Button
        type="primary"
        shape="round"
        onClick={checkSelectDay}
        className="  mt-2 mb-4 c-btn btn-success border border-0 me-2 "
      >
        تکمیل پرسشنامه
        <MdOutlineArrowBackIos className="  me-1 " />
      </Button>
    </div>
  );
};

export default SelectDays;
