import Global from "../config.json";
import httpService from "../services/httpService";
const UserApi = {
  async requestOTP(mobile) {
    let resStatus = 0;
    let res = [];
    await httpService
      .post(
        `${Global.HealyMealy}user/phone/request-otp/`,
        JSON.stringify({
          phone_number: mobile,
        })
      )

      .then((json) => {
        console.log(json, resStatus);
        switch (json.status) {
          case 200:
          case 201:
            res = json.data;
            break;
        
        }
      })
      

    return res;
  },
  async otpLogin(phoneNumber, number) {
    let res = { };

    let resStatus = 0;

    await httpService
      .post(
        `${Global.HealyMealy}user/otp-login/`,
        JSON.stringify({
          phone_number: phoneNumber.toString(),
          otp: number.toString(),
        })
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async loadProfile(jwt) {
    let res = { };

    await httpService
      .get(
        `${Global.HealyMealy}user/profile/`,
       {
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async editProfile(data,jwt) {
    let res = { };

    await httpService
      .post(
        `${Global.HealyMealy}user/profile/`,data,
       {
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async setUsername(username, token) {
    let res = { };

    let resStatus = 0;

    await httpService
      .post(
        `${Global.HealyMealy}user/profile/`,
        JSON.stringify({
          nickname: username,
        }),
        {headers:{Authorization: "Bearer "+token}}
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async computeBMI(gender, age,weight,height,jwt) {
    let res = { };

    await httpService
      .post(
        `${Global.HealyMealy}user/bmi/`,
        JSON.stringify({
          gender: gender,
          age: age,
          weight: weight,
          height: height,
        }),{
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async loadBMI(jwt) {
    let res = { };

    await httpService
      .get(
        `${Global.HealyMealy}user/bmi/`,
       {
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async loadFavorites(jwt) {
    let res = { };

    await httpService
      .get(
        `${Global.HealyMealy}product/favorite/`,
       {
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async taggoleFavorite(fid,jwt) {
    let res = { };

    await httpService
      .post(
        `${Global.HealyMealy}product/${fid}/favorite/`,{},
      {
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response);
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
  async loadCalories(jwt) {
    let res = { };

    await httpService
      .get(
        `${Global.HealyMealy}user/calories-chart/`,
       {
          headers:jwt
        }
      )
      .then((response) => {
        console.log(response,"chart");
        switch (response.status) {
          case 200:
          case 201:
            res = response.data;
            break;
        }
      })
    

    return res;
  },
}
export default UserApi;