import React from "react";
import ListBasket from "./ListBasket";

const Step1 = () => {
  return (
    <div>
      <div className="mt-4">
        <h6>سبد خرید</h6>
      </div>
      <ListBasket />
    </div>
  );
};

export default Step1;
