import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Panel = ({ children }) => {
  const jwt = useSelector((state) => state.jwt);
  const navigate=useNavigate()
  useEffect(()=>{
    // if(!jwt)
    // navigate("/")
  },[])
  return (
      <div className="container panel">
        <Sidebar />
        <div><Outlet /></div>
      </div>
  );
};

export default Panel;
