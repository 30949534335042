import { Button } from "antd";
import React, { useState } from "react";
import Global from "../config.json";
import { BiCheckCircle } from "react-icons/bi";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { message, Upload } from "antd";
import { useSelector } from "react-redux";
import { CgSoftwareUpload } from "react-icons/cg";
const { Dragger } = Upload;
const UploadFiles = (props) => {
  const jwt = useSelector((state) => state.jwt);
  const [files, setFiles] = useState([])
  const _props = {
    name: "file",
    multiple: true,
    data: { description: "clinical_report" },
    action: `${Global.HealyMealy}upload/image/`,
    headers: jwt,
    onRemove: (file) => {
        const index = files.indexOf(file);
        const newFileList = files.slice();
        newFileList.splice(index, 1);
        setFiles(newFileList);
      },
    onChange(info) {
      const { status } = info.file;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        setFiles(info.fileList)
      }

      if (status === "done") {
        message.success(` فایل بدرستی آپلود شد.`);

      } else if (status === "error") {
        message.error(` مشکلی در آپلود فایل وجود دارد.`);
      }
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const finishUpload=()=>{
    props.saveFiles(files)
  }
  const CancelUpload=()=>{
    files.map(file=>{

    })
    props.cancel()
  }
  return (
    <div className="d-grid justify-contents-center text-center m-auto upload-body">
      <BiCheckCircle className="w-100 mt-3 mb-2" size={32} color="green" />
      <h6>آپلود فایل</h6>
      <p className="text-end">
        تصویر برگه آزمایش خود را در این قسمت بارگذاری نمایید.
      </p>
      <p className="text-end" style={{ color: "#198754" }}>
        <MdOutlineTipsAndUpdates color="#198754" className="ms-1" />
        در صورت نداشتن نتیجه آزمایش در زمان بعد هم میتوانید بارگذاری کنید.
      </p>
      <div className="upload-box">
        <Dragger {..._props}>
          <p className="ant-upload-drag-icon">
            <CgSoftwareUpload size={32} />
          </p>
          <p className="ant-upload-text">برای بارگذاری کلیک کنید،</p>
          <p className="ant-upload-hint">یا فایل را در این قسمت رها کنید.</p>
        </Dragger>
        <div className="d-f-j-a mt-3">
        <Button shape="round" className=" c-btn btn-success p-3 w-25" onClick={finishUpload}>
          اتمام
        </Button>
        <Button shape="round" className=" c-btn w-25  " onClick={CancelUpload}>
          بعدا ارسال میکنم
        </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
