import React from "react";
import { Provider } from "react-redux";
import {BrowserRouter ,HashRouter} from "react-router-dom"
import HealyMealy from "./HealyMealy";
import { store } from "./redux/store";
const App = () => {
    return (
        <Provider store={store} >
        <BrowserRouter>
            <HealyMealy />
        </BrowserRouter>
        </Provider>
    );
};


export default App;