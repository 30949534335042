import React, { useEffect } from "react";
import Panel from "../../layouts/Panel";
import Tabs from "../../commons/Tabs";
import UserApi from "../../api/UserApi";
import { useState } from "react";
import { useSelector } from "react-redux";
import ItemFavorite from "./components/ItemFavorite";
import { Alert } from "antd";
const List = () => {
  const [favorites, setFavorites] = useState([]);
  const jwt = useSelector((state) => state.jwt);
  const tabs = [
    {
      icon: <img src="/icon/fav-list.svg" width={30} />,
      title: "غذاهای هیلی میلی",
      count: 0,
    },
    {
      icon: <img src="/icon/fav-mylist.svg" width={30} />,
      title: "غذاهای خودم",
      count: 0,
    },
  ];
  const loadData = async () => {
    try {
      const _res = await UserApi.loadFavorites(jwt);
      setFavorites(_res);
    } catch (e) {}
  };
  useEffect(() => {
    loadData();
  }, []);
  const onDelete = (item) => {
    const _fav = favorites.filter((fav) => fav !== item);
    setFavorites(_fav);
  };
  return (
    <>
      <div className="list">
        <Tabs data={tabs} />
        {favorites.length > 0 ? (
          <div className="foods-list mt-3">
            {favorites?.map((item, index) => {
              return <ItemFavorite food={item} key={index} delete={onDelete} />;
            })}
          </div>
        ) : (
          <Alert
            className="mt-4"
            message="در حال حاضر لیست علاقه مندی های شما خالیست"
            type="info"
          />
        )}
      </div>
    </>
  );
};

export default List;
