import { Carousel } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import CommentsAPI from '../../../api/CommentsAPI'

const Comments = () => {
    const [comments, setComments] = useState([])

    useEffect(()=>{
        loadComments()

    },[])
    const loadComments=async()=>{
        try{
          const _res=await CommentsAPI.loadLanding()
          setComments(_res)
        }catch(e){
    
        }
      }
  return (
    <div className="comments mt-5 pt-5 mb-5">
    <div className="container-fluid c-b-silver p-2">
      <div className="container p-5">
        <h2 className="c-orange text-center w-100 mb-5">نظر کاربران</h2>
        <Carousel  autoplay dots={false}>
          <div className="item">
            <div className="p-1 text-end">
              <h5>میلاد</h5>
              <p>
                اب و ،پاچ تعنص زا موهفمان یگداس دیلوت اب یگتخاس نتم موسپیا
                مرولهلجم و همانزور هکلب نوتم و اهرگپاچ ،تسا کیفارگ ناحارط
                زا هدافتسایلعف طیارش یارب و ،تسا مزال هک نانچنآرطس و نوتس
                رد
              </p>
            </div>
            <img
              className="rounded ps-1"
              src="/images/users/avatar.png"
            />
          </div>
          <div className="item">
            <div className="p-1 text-end">
              <h5>جعفر</h5>
              <p>
                اب و ،پاچ تعنص زا موهفمان یگداس دیلوت اب یگتخاس نتم موسپیا
                مرولهلجم و همانزور هکلب نوتم و اهرگپاچ ،تسا کیفارگ ناحارط
                زا هدافتسایلعف طیارش یارب و ،تسا مزال هک نانچنآرطس و نوتس
                رد
              </p>
            </div>
            <img
              className="rounded ps-1"
              src="/images/users/avatar.png"
            />
          </div>
          <div className="item">
            <div className="p-1 text-end">
              <h5>میلاد</h5>
              <p>
                اب و ،پاچ تعنص زا موهفمان یگداس دیلوت اب یگتخاس نتم موسپیا
                مرولهلجم و همانزور هکلب نوتم و اهرگپاچ ،تسا کیفارگ ناحارط
                زا هدافتسایلعف طیارش یارب و ،تسا مزال هک نانچنآرطس و نوتس
                رد
              </p>
            </div>
            <img
              className="rounded ps-1"
              src="/images/users/avatar.png"
            />
          </div>
        {/* </div> */}
        </Carousel>
      </div>
    </div>
  </div>
  )
}

export default Comments