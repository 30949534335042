import React, { createContext } from 'react'
const QuestionContext=createContext({
    questions:[],
    setProcessStep1:[0],
    getProcessStep1:[0],
    setProcessStep2:[0],
    getProcessStep2:[0],
    setProcessStep3:[0],
    getProcessStep3:[0],
    setProcessStep4:[0],
    getProcessStep4:[0],
    setProcessStep5:[0],
    getProcessStep5:[0],
})
export default QuestionContext;