import React, { useState } from "react";
import Panel from "../../layouts/Panel";
import { BiLocationPlus } from "react-icons/bi";
import { Card, Input, List, Button, Form } from "antd";
import { useEffect } from "react";
import { useContext } from "react";
import MainContext from "../../context/MainContext";
import UserApi from "../../api/UserApi";
import { useSelector } from "react-redux";
import AddressApi from "../../api/AddressApi";
import ModalAddress from "../../modals/ModalAddress";

const Profile = () => {
  const context = useContext(MainContext);
  const [boxAddress, setBoxAddress] = useState(false);
  const [profile, setProfile] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const jwt = useSelector((state) => state.jwt);
  const loadAddress = async () => {
    try {
      const _res = await AddressApi.loadAddresses(jwt);
      setAddresses(_res);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  const loadProfile = async () => {
    try {
      const _res = await UserApi.loadProfile(jwt);
      setProfile(_res);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  useEffect(() => {
    loadProfile();
    loadAddress();
  }, []);
  const onSubmit = async () => {
    try {
      delete profile.avatar;
      await UserApi.editProfile(profile, jwt);
    } catch (e) {
      console.log(e)
      context.handleMsg(e, "error");
    }
  };
  const toggleBoxAddress = () => {
    setBoxAddress(!boxAddress);
  };
  const onUpdate = () => {
    loadAddress();
  };
  const onChangeFullName = (e) => {
    let _profile = { ...profile };
    _profile.full_name = e.target.value;
    setProfile(_profile);
  };
  const onChangeNickname = (e) => {
    let _profile = { ...profile };
    _profile.nickname = e.target.value;
    setProfile(_profile);
  };
  const onChangeEmail = (e) => {
    let _profile = { ...profile };
    _profile.email = e.target.value;
    setProfile(_profile);
  };
  const [form] = Form.useForm();
  return (
    <>
      <div className="profile">
        <Card title="اطلاعات کاربری" size="small" className="d-grid">
        <Form
      name="basic"
      form={form}
    //   labelCol={{ span: 8 }}
    //   wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
    //   onFinish={onFinish}
    //   onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
          <div className="d-f-j-a">
            <Form.Item
              className="ms-5 d-grid w-100 input-bottom-border"
              label="نام و نام خانوادگی"
            >
              <Input
                bordered={false}
                onChange={onChangeFullName}
                value={profile.full_name}
              />
            </Form.Item>
            <Form.Item
              className="me-5 d-grid w-100 input-bottom-border"
              label="شماره همراه"
              value={profile?.phone_number}
            >
              <Input bordered={false} value={profile.phone_number} disabled />
            </Form.Item>
          </div>
          <div className="d-f-j-a mt-3">
            <Form.Item
              className="ms-5 d-grid w-100 input-bottom-border"
              label="نام نمایشی"
              rules={[
               
                {
                  required: true,
                  message: "لطفا نام نمایشی خود را وارد کنید",
                },
              ]}
            >
              <Input value={profile.nickname} 
                 bordered={false} 
                
                onChange={onChangeNickname} 
                placeholder="مثال: امیر" 
             /> 
            </Form.Item>
            <Form.Item
              className="me-5 w-100 d-grid input-bottom-border"
              label="ایمیل"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: false,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                bordered={false}
                placeholder=" مثال  amir@domain.com"
                value={profile.email}
                onChange={onChangeEmail}
              />
            </Form.Item>
           
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <Button
              type="primary"
              shape="round"
              onClick={onSubmit}
              className="w-25 mt-2 mb-4 c-btn btn-success border border-0 "
            >
              ویرایش پروفایل
            </Button>
          </div>
          </Form>
        </Card>
        <Card title="آدرس ها" size="small">
          <div
            style={{ color: "#6E9947" }}
            role={"button"}
            onClick={toggleBoxAddress}
          >
            <BiLocationPlus role={"button"} color="#6E9947" /> افزودن آدرس جدید{" "}
          </div>
          <div>
            <List
              itemLayout="horizontal"
              dataSource={addresses}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <a key="list-loadmore-edit">ویرایش</a>,
                    <a key="list-loadmore-edit">حذف</a>,
                  ]}
                >
                  <List.Item.Meta
                    title={<a >{item.name}</a>}
                    description={`${item.address} - ${item?.postal_code ?? ""}`}
                  />
                </List.Item>
              )}
            />
          </div>
        </Card>
      </div>
      <ModalAddress
        onUpdate={onUpdate}
        showModal={boxAddress}
        handleCancel={toggleBoxAddress}
      />
    </>
  );
};

export default Profile;
