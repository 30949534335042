import React, { useContext, useState } from "react";
import MainContext from "../context/MainContext";

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  const context=useContext(MainContext)
  const onActiveTab=(index)=>{
    setActiveTab(index)
    props.activeTab(index)
  }
  return (
    <div className="tabs">
      {props.data.map((tab,index) => {
        return (
          <div role={"button"} onClick={()=>onActiveTab(index)} className={`tab  ${index===activeTab?"active":null}` } key={index}>
           {context.checkWindow().width>480? <span>{tab.icon}</span>:null} 
            <span className="fw-bold">{tab.title}</span>
            <span className="fw-bold">{tab.count}</span>
          </div>
        );
      })}

  
    </div>
  );
};

export default Tabs;
