import React from 'react'
import moment from 'jalali-moment'
const DaySelect = ({data}) => {
  return (
    <div className='d-f-j-a day-select m-2'>
        <span>{moment(data.p_day,"jYYYY/jMM/jDD").locale("fa").format("dddd")}</span>
        <span>{data.p_day}</span>
        <span>{data.meal} وعده</span>
        <span>{data.food} غذا</span>
        <span>{data.receipt_time}</span>
    </div>
  )
}

export default DaySelect