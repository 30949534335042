import Global from "../config.json";
import httpService from "../services/httpService";
const QuestionsAPI={
    async loadQuestions(jwt) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}regimen/question/`,{
            headers:jwt
          })
          .then((json) => {
            switch (json.status) {
              case 201:
              case 200:
                res = json.data;
                break;
            }
          })
        return res;
      },
      async loadOptions(type,jwt) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}regimen/items/?type=${type}`,{
            headers:jwt
          })
          .then((json) => {
            switch (json.status) {
              case 201:
              case 200:
                res = json.data==""?[]: json.data;
                break;
            }
          })
        return res;
      },
      async sendStepsData(data,jwt) {
        let res = [];
        await httpService
          .post(`${Global.HealyMealy}regimen/answer/`,{answers:data},{
            headers:jwt
          })
          .then((json) => {
            switch (json.status) {
              case 201:
              case 200:
                res = json.data;
                break;
            }
          })
        return res;
      },
}
export default QuestionsAPI