import React, { useContext, useEffect, useState } from "react";
import Master from "../../layouts/Master";
import { RiShoppingBasketLine } from "react-icons/ri";
import SelectDate from "../../commons/SelectDate";
import Meals from "./components/Meals";
import BMI from "./components/BMI";
import Basket from "./components/Basket";
import MainContext from "../../context/MainContext";
import { Button } from "antd";
import UserApi from "../../api/UserApi";
import { useNavigate, useParams } from "react-router-dom";
import CartApi from "../../api/CartApi";
import { useDispatch, useSelector } from "react-redux";
import PackageApi from "../../api/PackageApi";
import moment from "jalali-moment";
import { saveBasketAction } from "../../redux/actions/basketActions";
import BasketApi from "../../api/BasketApi";
import { loadPackageCalendarAction } from "../../redux/actions/packetActions";
import { loadPackagesAction, startPackagesAction } from "../../redux/actions/packagesActions";
import SelectDatePackage from "../../commons/SelectDatePackage";
const Order = () => {
  const context = useContext(MainContext);
  const jwt = useSelector((state) => state.jwt);
  const basket = useSelector((state) => state.basket);
  const [isLoading, setIsLoading] = useState(false);
  const packet = useSelector((state) => state.packet);
  const packages = useSelector((state) => state.packages);
  const [date, setDate] = useState(moment().add(2, "days"));
  const [bmi, setBmi] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const loadPackage = async () => {
    try {
      dispatch(loadPackagesAction(jwt, context));
    } catch (error) {
      context.handleMsg(error, "error");
    }
  };
  const checkPackage = async () => {
    try {
      const _res = await PackageApi.startPackage(params.pid, jwt);
      dispatch(startPackagesAction(_res))
      console.log(_res);
    } catch (error) {
      if (error.response.status == 400) {
        context.handleMsg(
          "این پکیج را قبلا ثبت کرده اید\nدر حال حاضر امکان ویرایش این پکیج برای شما فراهم است",
          "info"
        );
        loadPackage();
      } else context.handleMsg(error, "error");
    }
  };
  const loadCalendar = async () => {
    try {
      dispatch(
        loadPackageCalendarAction(params.pid, packages[0].id, jwt, context)
      );
    } catch (e) {
      context.handleMsg(e);
    }
  };
  useEffect(() => {
    context.checkLogin();
  }, []);
  useEffect(() => {
    if (jwt) checkPackage();
    // loadOrder()
  }, [jwt]);
  useEffect(() => {
    loadCalendar();
  }, [packages]);

  const activePackage = async () => {
    try {
      setIsLoading(true);
      const _res = await BasketApi.activePackage(jwt);
      setIsLoading(false);
      navigate(`/basket/${params.pid}`);
    } catch (error) {
      context.handleMsg(error, "error");
    }
  };
  return (
      <div className="container order">
        <div>
          <SelectDatePackage
            selectedDate={date}
            labelDate={true}
            changeSelectedDate={setDate}
          />

          <Meals data={basket} selectedDate={date} />
        </div>
        <div>
          <div className="d-block">
            <BMI />
          </div>
          <div className="mt-5">
            <Button
              type="primary"
              shape="round"
              loading={isLoading}
              onClick={activePackage}
              className="w-100  mt-2 mb-4 c-btn btn-success border border-0 "
              icon={<RiShoppingBasketLine className="  ms-1" />}
            >
              برو به سبد خرید
            </Button>
          </div>
          <div>
            <Basket />
          </div>
        </div>
      </div>
  );
};

export default Order;
