import { Carousel } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommentsAPI from "../../../api/CommentsAPI";
const settings = {
  // className: "center",
  centerMode: false,
  infinite: true,
  centerPadding: "0px",
  slidesToShow: 3,
  speed: 500,
  // className:"persons m-0",
  responsive: [
    {
      breakpoint: 480,
      settings: {
  centerMode: true,
  centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Packages = () => {
  const navigate=useNavigate();
  // const [comments, setComments] = useState([]);

  // useEffect(() => {
  //   loadComments();
  // }, []);
  // const loadComments = async () => {
  //   try {
  //     const _res = await CommentsAPI.loadLanding();
  //     setComments(_res);
  //   } catch (e) {}
  // };
  const showPack=(id)=>{
    navigate("/pack/1")
  }
  return (
    <div id="packages" className="packages mt-5 mb-5 pb-5">

        <div className="container ">
          <h2 className="c-orange text-center w-100 mb-5">پکیج های غذایی</h2>
          <Carousel  {...settings}>
            <div onClick={()=>showPack(1)}  role="button" className="item my_slide position-relative ps-3">
              <h4 className="titr c-orange">زنان باردار</h4>
              <img className="person" src="/images/pregnant.png" />
              <a href="#" role="button" className="link">
                <img
                  role="button"
                  src="/icon/circle-arrow-left.svg"
                  className="float-start animate-right-btn"
                />
              </a>
              <img src="/images/package-frame.svg" alt="" />
            </div>
            <div onClick={()=>showPack(1)}  role="button" className="my_slide position-relative ps-3">
                <h4 className="titr c-orange">ورزشکاران</h4>
                <img className="person" src="/images/man.png" />
                <a href="#" role="button" className="link">
                  <img
                    role="button"
                    src="/icon/circle-arrow-left.svg"
                    className="float-start animate-right-btn"
                  />
                </a>
                <img src="/images/package-frame.svg" alt="" />
              </div>
              <div onClick={()=>showPack(1)}  role="button" className="my_slide position-relative ps-3">
                <h4 className="titr c-orange">گیاه خواران</h4>
                <img className="person" src="/images/vegetarian.png" />
                <a href="#" role="button" className="link">
                  <img
                    role="button"
                    src="/icon/circle-arrow-left.svg"
                    className="float-start animate-right-btn"
                  />
                </a>
                <img src="/images/package-frame.svg" alt="" />
              </div>
          </Carousel>
      </div>
      </div>
  );
};

export default Packages;
