import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BasketApi from '../../../api/BasketApi'
import MainContext from '../../../context/MainContext'
import {FaRegEdit} from "react-icons/fa"
import moment from 'jalali-moment'
import ItemsBasket from '../../../commons/ItemsBasket'
import {  useNavigate, useParams } from 'react-router-dom'
const ListBasket = () => {
    const context=useContext(MainContext)
    const params=useParams()
    const navigate=useNavigate()
    const jwt=useSelector(state=>state.jwt)
    const [data, setData] = useState([])
    const loadBasket=async()=>{
        try {
            const _res=await BasketApi.loadBasket(jwt)
            setData(_res)
      
        } catch (error) {
            context.handleMsg(error)
        }
    }
    useEffect(()=>{
        if(jwt)
        loadBasket()
    },[jwt])
  return (
    <div>{
        data?.map((day,index)=>{
            return( 
                <div className='foods' key={index}>
                    <div className='d-f-j-a'>
                    <span className='fw-bold'>{moment(day.p_day,"jYYYY/jMM/jDD").locale("fa").format("dddd jYYYY/jMM/jDD ")}</span>
                    
                    {/* <a onClick={()=>navigate(`/pack/${params.pid}/order`)}>ویرایش <FaRegEdit /></a> */}
                    </div>
                    <div className="foods-list">
                        {day.orders.map((order,index2)=>{
                            return (
                                <ItemsBasket disabled={true} food={order} key={index2} />

                            )
                        })}
                    </div>
                </div>
            )
        })
        }</div>
  )
}

export default ListBasket