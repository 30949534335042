import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QuestionsAPI from "../../../api/QuestionsAPI";
import MainContext from "../../../context/MainContext";
import QuestionContext from "../../../context/QuestionContext";
import Panel from "../../../layouts/Panel";
import StepBody from "./components/StepBody";
import StepsQuestions from "./components/StepsQuestions";

const FillForm = () => {
  const [activeStep, setActiveStep] = useState(1);
  const jwt = useSelector((state) => state.jwt);
  const navigate = useNavigate();
  const stepBodyRef = useRef();
  const [questions, setQuestions] = useState([]);
  const [process1, setProcess1] = useState([0]);
  const [process2, setProcess2] = useState([0]);
  const [process3, setProcess3] = useState([0]);
  const [process4, setProcess4] = useState([0]);
  const [process5, setProcess5] = useState([0]);
  const context = useContext(MainContext);
  const [, forceUpdate] = useState(0);
  const processQuestion = (data) => {
    try {
      let _questions = {
        information: [],
        anthropometry: [],
        general_assessment: [],
        clinical_assessment: [],
        food_pattern: [],
      };
      data.map((item, index) => {
        switch (item.category) {
          case "information":
            _questions.information.push(item);
            break;
          case "anthropometry":
            _questions.anthropometry.push(item);
            break;
          case "general_assessment":
            _questions.general_assessment.push(item);
            break;
          case "clinical_assessment":
            _questions.clinical_assessment.push(item);
            break;
          case "food_pattern":
            _questions.food_pattern.push(item);
            break;
        }
      });
      return _questions;
    } catch (e) {
      console.log(e);
      return [];
    }
  };
  const loadQuestions = async () => {
    try {
      console.log(jwt);

      const res = await QuestionsAPI.loadQuestions(jwt);

      const _questions = processQuestion(res);
      console.log(_questions);

      setQuestions(_questions);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  useEffect(() => {
    if (jwt) loadQuestions();
  }, [jwt]);

  const setStepProcess1 = (index, _process) => {
    process1[index] = _process;
    setProcess1(process1);
    forceUpdate(Math.random());
  };
  const setStepProcess2 = (index, _process) => {
    process2[index] = _process;
    setProcess2(process2);
    forceUpdate(Math.random());
  };
  const setStepProcess3 = (index, _process) => {
    process3[index] = _process;
    setProcess3(process3);
    forceUpdate(Math.random());
  };
  const setStepProcess4 = (index, _process) => {
    process4[index] = _process;
    setProcess4(process4);
    forceUpdate(Math.random());
  };
  const setStepProcess5 = (index, _process) => {
    process5[index] = _process;
    setProcess5(process5);
    forceUpdate(Math.random());
  };
  const nextStep = async () => {
    await stepBodyRef.current.onSubmit();
    setActiveStep(activeStep + 1);
  };
  const backStep = () => {
    if (activeStep === 1) navigate("/panel/consultant/list");
    else setActiveStep(activeStep - 1);
  };
  return (
    <>
      <QuestionContext.Provider
        value={{
          questions: questions,
          setProcessStep1: setStepProcess1,
          getProcessStep1: process1,
          setProcessStep2: setStepProcess2,
          getProcessStep2: process2,
          setProcessStep3: setStepProcess3,
          getProcessStep3: process3,
          setProcessStep4: setStepProcess4,
          getProcessStep4: process4,
          setProcessStep5: setStepProcess5,
          getProcessStep5: process5,
        }}
      >
        <div className="fill-form">
          <StepsQuestions
            activeStep={activeStep}
            backStep={backStep}
            nextStep={nextStep}
          />

          <StepBody ref={stepBodyRef} activeStep={activeStep} />
        </div>
      </QuestionContext.Provider>
    </>
  );
};

export default FillForm;
