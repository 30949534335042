import { Alert, Button, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CartApi from "../../../api/CartApi";
import Modify from "../../../commons/Modify";
import MainContext from "../../../context/MainContext";

const MealsHistory = (props) => {
  const [data, setData] = useState([]);
  const context = useContext(MainContext);
  const packet = useSelector((state) => state.packet);
  const jwt = useSelector((state) => state.jwt);
  const checkData = (data) => {
    let _isEmpty = true;
    data?.map((meal) => {
      if (meal.product.length > 0) _isEmpty = false;
    });
    if (_isEmpty) setData([]);
    else setData(data);
  };
  const loadOrderInfo = async (oid) => {
    try {
      const _res = await CartApi.listOrder(oid, jwt);
      console.log(_res);
      checkData(_res.data);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  useEffect(() => {
    console.log(packet);
    packet?.orders?.map((order) => {
      if (
        order.p_day == props.selectedDate.locale("fa").format("jYYYY/jMM/jDD")
      ) {
        loadOrderInfo(order.id);
      }
    });
  }, [props]);
  return (
    <div className="meals">
      {data.length > 0 ? (
        data?.map((meal, index) => {
          return (
            <div className="meal" key={index}>
              <h6>{meal.meal} </h6>
              <div className="recipes">
                {meal?.product?.map((product, index) => (
                  <div className="recipe" key={index}>
                    <div className="recipe-box position-relative">
                      <img
                        src={product.product.cover}
                        className="h-100 w-100 "
                        alt=""
                      />
                    </div>
                    <span>{product.product.name}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        })
      ) : (
        <Alert
          message="‌ ‌عدم سفارش‌  "
          description="در حال حاضر سفارسی برای این روز ثبت نشده است"
          type="info"
          showIcon
        />
      )}
    </div>
  );
};

export default MealsHistory;
