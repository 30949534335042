import { Modal } from "antd";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Spin } from "antd";

import { useSelector } from "react-redux";

import MainContext from "../context/MainContext";
import { useDispatch } from "react-redux";
import UserApi from "../api/UserApi";
import { saveJWTAction } from "../redux/actions/jwtActions";
import Timer from "../commons/Timer";
// import Timer from "..//Timer";

const Register = forwardRef((props, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const jwt = useSelector((state) => state.jwt);
  const context = useContext(MainContext);
  const [codeBox, setCodeBox] = useState(false);
  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [otpData, setOtpData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const codeRef = useRef();
  const mobileRef = useRef();
  const usernameRef = useRef();
  const dispatch = useDispatch();
  const handleCancel = () => {
    setIsModalVisible(false);
    onReturn()
    setUsername("")
    setMobile("")
    setCode("")
  };
  useImperativeHandle(ref, () => ({
    show(visible) {
      setIsModalVisible(visible);
    },
  }));
  useEffect(() => {
    if (jwt) {
      context.checkLogin();
    }
  }, [jwt]);

  const requestOTP = async () => {
    try {
      let _res = await UserApi.requestOTP(mobile);
      setOtpData(_res);
      setCodeBox(true);
    } catch (e) {
      context.handleMsg(e, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const sentCode = async () => {
    try {
      let _res = await UserApi.otpLogin(mobile, code);
      if (otpData.set_username === true) dispatch(saveJWTAction(_res));
      else {
        setShowUsername(true);
        setToken(_res);
      }
    } catch (e) {
      context.handleMsg(e, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const sentUsername = async () => {
    try {
      console.log(token);
      let _res = await UserApi.setUsername(username, token.access_token);
      dispatch(saveJWTAction(token));
    } catch (e) {
      context.handleMsg(e, "error");
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmit = async () => {
    setIsLoading(true);

    if (!codeBox && !showUsername) {
      requestOTP();
    } else if (codeBox && !showUsername) sentCode();
    else sentUsername();
  };
  const onReturn = () => {
    setCodeBox(false);
    setCode("");
  };

  return (
    <Modal
      closable={true}
      onCancel={handleCancel}
      title="ورود | ثبت نام"
      footer={null}
      bodyStyle={{ borderRadius: 100 }}
      visible={isModalVisible}
    >
      <div>
        {!codeBox && !showUsername && (
          <div className="form-group">
            <label htmlFor="username">شماره همراه</label>
            <input
              className="form-control"
              ref={mobileRef}
              type={"text"}
              pattern="\d*"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onSubmit();
                }
              }}
              onChange={(e) => setMobile(e.target.value)}
              value={mobile}
              placeholder="مثال: 09011234434"
            />
          </div>
        )}
        {codeBox && !showUsername && (
          <>
            <div className="form-group">
              <label htmlFor="username">کد ارسالی</label>
              <label className="float-start">
                <Timer data={otpData} requestOTP={requestOTP} />
              </label>
              <input
                className="form-control"
                type={"number"}
                ref={codeRef}
                value={code}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    onSubmit();
                  }
                }}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                placeholder="مثال: 123456"
              />
              <Button
                className="float-start p-0"
                onClick={onReturn}
                type="link"
              >
                برگشت
                <img src="/images/Arrow_left2.svg" height={20} alt="" />
              </Button>
            </div>
          </>
        )}
        {codeBox && showUsername && (
          <div className="form-group">
            <label htmlFor="username">نام کاربری</label>
            <input
              className="form-control"
              ref={usernameRef}
              type={"text"}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onSubmit();
                }
              }}
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="مثال: امیرعلی"
            />
          </div>
        )}
        <div className="box-btn--white m-auto mt-3" id="register">
          <button className="btn btn--white btn-no-motion" onClick={onSubmit}>
            {isLoading && <Spin className="ms-3 mt-1" />}
            ورود
            <img src="/icon/register.svg" className="icon-register" />
          </button>
        </div>
      </div>
    </Modal>
  );
});
export default Register;
