import { Button, InputNumber, Select } from 'antd'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import {BsArrowLeft} from "react-icons/bs"
import { useDispatch, useSelector } from 'react-redux'
import MainContext from '../../../context/MainContext'
import { getPropertyBMIAction, setPropertyBMIAction } from '../../../redux/actions/bmiActions'
const ComputeBMI = (props) => {
    const [age, setAge] = useState(32)
    const [gender, setGender] = useState("m")
    const [height, setHeight] = useState(185)
    const [weight, setWeight] = useState(62)
    const dispatch=useDispatch()
    const context=useContext(MainContext)
    const jwt =useSelector(state=>state.jwt)
    const onSubmit=async()=> {
            dispatch(setPropertyBMIAction(gender,age,weight,height,jwt,context))
    }
    const loadBody=()=>{
      dispatch(getPropertyBMIAction(jwt,context))
    }
    useEffect(()=>{
        loadBody()
    },[])
  
  return (
    <div className='compute-bmi mt-4'>
        <h6>برای محاسبه کالری موردنیاز شما، اطلاعات زیر را پر نمایید:</h6>
        <div className='form-bmi d-flex justify-content-between align-items-center'>
            <label>قد</label>
            <InputNumber  value={height} onChange={setHeight} className='w-25' max={250} min={130} />
            <label>وزن</label>
            <InputNumber   value={weight} onChange={setWeight}  className='w-25' max={160} min={30} />
        </div>
        <div className='form-bmi d-flex justify-content-between align-items-center'>
            <label>سن</label>
            <InputNumber value={age} onChange={setAge}   className='w-25'  max={120} min={10} />
            <label>جنس</label>
            <Select
      defaultValue={gender}
      className='w-25'
      onChange={setGender}
    >
      <Select.Option value="m">مرد</Select.Option>
      <Select.Option value="f">زن</Select.Option>
     
    </Select>
        </div>
        <div>
        <Button type="primary" shape="round" onClick={onSubmit} className='w-100    mt-2 mb-4 c-btn btn-success border border-0 ' dir='ltr' icon={<BsArrowLeft  className='  me-1' />}  >
            محاسبه
        </Button>

        </div>
    </div>
  )
}

export default ComputeBMI