import PackageApi from "../../api/PackageApi";


// export const savePackageAction = (data) => {
//   return async (dispatch) => {
//     try {
     
//       await dispatch({ type: "PACKET", payload: data });
//     } catch (e) {
//       context.handleMsg(e);
//     }
//   };
// };
// export const loadPackagesAction = (jwt,context) => {
//   return async (dispatch) => {
//     try {
//       let _res = await PackageApi.loadActivePackages( jwt);

//       await dispatch({ type: "PACKET", payload: _res[0] });
//     } catch (e) {
//       context.handleMsg(e,"error");
//     }
//   };
// };
export const loadPackageCalendarAction = (pid,puid,jwt,context) => {
  return async (dispatch) => {
    try {
      let _res = await PackageApi.packageCalendar( pid,puid,jwt);
    console.log(_res,55555555555)
      await dispatch({ type: "PACKET", payload: _res });
    } catch (e) {
      context.handleMsg(e,"error");
    }
  };
};