import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { CgMathPlus, CgMathMinus } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import MainContext from "../context/MainContext";
import { changeBasketOnlineAction } from "../redux/actions/basketActions";
import { addCardAction, removeCardAction } from "../redux/actions/cardActions";
const Modify = (props) => {
  const [count, setCount] = useState(0);
  const basket = useSelector((state) => state.basket);
  const jwt=useSelector(state=>state.jwt)
  const context=useContext(MainContext)
  const dispatch = useDispatch();
  const addToBasket = () => {
    
    dispatch(addCardAction(props.food,props.meal.en_meal,context,jwt));
    setCount(count + 1);
    context.refresh()
  };
  const removeFromBasket = () => {
    dispatch(removeCardAction(props.food,props.meal.en_meal,context,jwt));
    setCount(count - 1);
    context.refresh()
  };
  useEffect(() => {
    setCount(props.food.number);
  }, []);
  return (
    <div
      className="modify d-grid justify-content-center align-items-center text-center bg-white h-100 position-absolute right-0 top-0 "
      style={{ width: 30 }}
    >
      <CgMathPlus className="c-orange" role={"button"} onClick={addToBasket} />
      <span>{count}</span>
      <CgMathMinus
        className="c-orange"
        onClick={removeFromBasket}
        role={"button"}
      />
    </div>
  );
};

export default Modify;
