export const cardReducer = (state = [], action) => {
    switch (action?.type) {
        case "UPDATE_CARD":
            return action.payload;
        case "ADD_CARD":
                return action.payload;
        case "GET":
                return state;
        default:
            return state;
    }
}