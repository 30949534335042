import React from "react";
import {Input,Button} from "antd"
const ContactUs = () => {
  return (
    <div className="contact mb-5 pb-5">
      <form>
        <h6>ارتباط با ما</h6>
        <p>
          این‌جا صفحه‌ی خواننده‌های ماست.شما می‌توانید اینجا برایمان پیغام
          بگذارید، به هیلی میلی پیشنهاد بدهید و از آن انتقاد کنید. منتظر خواندن
          پیام‌هایتان هستیم و البته تک‌تکشان نزد ما محفوظ می‌مانند.
        </p>
        <div className="d-flex m-1 p-0 mb-3">
          <Input placeholder="نام"  className="form-control p-2 "/>
          <Input placeholder="ایمیل"  className="form-control p-2 me-3"/>
        </div>
        <div className="m-1 mb-3">
          <Input placeholder="موضوع" className="form-control p-2 "  />
        </div>
        <div className="m-1">
          <Input.TextArea rows={4} placeholder="پیغام شما" maxLength={6} className="form-control p-2"/>
        </div>
        <div className=" mt-3">
        <Button
        //   onClick={props.nextStep}
          shape="round"
          className="btn-next-green float-start "
        >
          ارسال
        </Button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
