import React, { useEffect, useState } from "react";
import { Modal, List } from "antd";
import { BiLocationPlus } from "react-icons/bi";
import AddressApi from "../api/AddressApi";
import {useSelector} from "react-redux"
import ModalMap from "./ModalMap";
import { useContext } from "react";
import MainContext from "../context/MainContext";
const ModalAddress = (props) => {
  const [boxMap, setBoxMap] = useState(false);
  const jwt=useSelector(state=>state.jwt)
  const context=useContext(MainContext)
  const [addresses, setAddresses] = useState([])
  const toggleBoxMap = () => {
    setBoxMap(!boxMap);
  };
  const loadData=async()=>{
    try{
      const _res=await AddressApi.loadAddresses(jwt)
      setAddresses(_res)
    }catch(e){
      context.handleMsg(e,"error")
    }
  }
  useEffect(() => {
    loadData();
  }, []);
  const onChangeAddress=(value)=>{

  }
  return (
    <Modal
      title="انتخاب آدرس"
      visible={props.showModal}
      footer={false}
      afterClose={props.onUpdate}
      onCancel={() => props.handleCancel()}
    >
      <span className="c-green" role={"button"} onClick={toggleBoxMap}>
        {" "}
        <BiLocationPlus /> افزودن آدرس جدید{" "}
      </span>
      <div className="d-flex pt-2 mt-2 w-100" style={{borderTop:1,borderTopStyle:"solid",borderColor:"#d5d5d5",borderTopWidth:1}}>
      <List
              itemLayout="horizontal"
              dataSource={addresses}
              className="w-100"

              renderItem={(item) => (
                <List.Item >
                  <List.Item.Meta
                    title={<a >{item.name}</a>}
                    description={`${item.address} - ${item?.postal_code ?? ""}`}
                  />
                </List.Item>
              )}
            />

      </div>
      <ModalMap showModal={boxMap} handleCancel={toggleBoxMap} />
    </Modal>
  );
};

export default ModalAddress;
