import Global from "../config.json";
import httpService from "../services/httpService";
const RecipeApi={
    async loadRecipe(q) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}recipe/?name=${q}`)
          .then((json) => {
            switch (json.status) {
              case 201:
              case 200:
                res = json.data.slice(0, 7);
                break;
            }
          })
        return res;
      },
      async loadUnits(rid) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}foodlogging/unit?item=recipe&id=${rid}`)
          .then((json) => {
            switch (json.status) {
              case 201:
              case 200:
                res = json.data.slice(0, 7);
                break;
            }
          })
        return res;
      },
      async computeCalories(amount,uid,rid,type) {
        let res = [];
        await httpService
          .post(`${Global.HealyMealy}material/nutrition/`,
            {amount:amount,unit:uid,item:rid,item_type:type}
          )
          .then((json) => {
            switch (json.status) {
              case 201:
              case 200:
                res = json.data;
                break;
            }
          })
        return res;
      },
}
 export default RecipeApi;