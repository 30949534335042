import React, { useContext, useState } from "react";
import { CgSearch, CgSortAz } from "react-icons/cg";
import { IoMdArrowDropdown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input } from "antd";
import MainContext from "../../../context/MainContext";
import CartApi from "../../../api/CartApi";
import { useEffect } from "react";
import { store } from "../../../redux/store";
import { updateCardAction } from "../../../redux/actions/cardActions";

function Tools(props) {
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(0)
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const params = useParams();
  const context=useContext(MainContext)
  store.subscribe(()=>{
    const _basket =store.getState().basket
    const _total=_basket.map(recipe => recipe.number).reduce((old, amount) => old + amount);
    setTotal(_total)
  })
  const [isLoading, setIsLoading] = useState(false);
  const basket = useSelector((state) => state.basket);
  const searchRecipe = (e) => {
    setQuery(e.target.value);
    props.loadFoods(e.target.value);
  };
  useEffect(()=>{
    console.log(222)
  },[basket])
  const addFoods = async () => {
    dispatch(updateCardAction(context))
    navigate(`/pack/${params.pid}/order/`)
  };

  return (
    <div className="d-flex align-items-center justify-content-between options">
      <Button
        type="primary"
        onClick={addFoods}
        shape="round"
        loading={isLoading}
        className="c-btn btn-success border border-0"
      >
        ثبت غذاهای انتخاب شده
      </Button>
      <Input
        placeholder="جستجو نام غذا"
        className="w-auto b-radius bg-white"
        prefix={<CgSearch className="p-0 ms-2" />}
        value={query}
        onChange={searchRecipe}
      />
      <span>تعداد غذاهای انتخابی : {total}</span>
      <div className="d-f-j-a">
        <CgSortAz
          className="c-orange border border-1 rounded-3 b-orange ms-2"
          size={24}
        />

        <strong>ترتیب بر اساس تاریخ</strong>
        <IoMdArrowDropdown size={24} />
      </div>
    </div>
  );
}

export default Tools;
