import BasketApi from "../../api/BasketApi";
import storage from "../../services/storage";
import { store } from "../store";

export const saveBasketAction = (data) => {
  return async (dispatch) => {
    await dispatch({ type: "ADD", payload: data });
  };
};

export const changeBasketOnlineAction = (food, count, jwt, context) => {
  return async (dispatch) => {
    try {
      const _list = store.getState().basket;
      _list.map((item) => {
        if (item.id === food.id) item.number = count;
      });
      let _res = await BasketApi.updateBasketFoods(_list, jwt);
      console.log(_res);
      await dispatch({ type: "ADD", payload: _list });
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
};
export const addBasketAction = (food, context, jwt) => {
  return async (dispatch) => {
    try {
      const _list = store.getState().basket;
      
      food.product = food.id;
      if (_list.length > 0) {
        let _food = _list.find((item) => item.id == food.id);
        if (_food) food.number = Number(_food?.number) + 1;
        else {
          food.number = 1;
          _list.push(food);
        }
      } else {
        food.number = 1;

        _list.push(food);
      }
      await BasketApi.addtoBasketFoods(food.oid, food.mid, _list, jwt);

      console.log(_list);

      await dispatch({ type: "ADD", payload: _list });
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
};

export const updateBasketAction = (food, count, context) => {
  return async (dispatch) => {
    try {
      const _list = store.getState().basket;
      _list.map((item, index) => {
        if (item.id == food.id) item.number = count;
      });

      console.log(_list);

      await dispatch({ type: "ADD", payload: _list });
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
};
export const removeBasketAction = (food, context, jwt) => {
  return async (dispatch) => {
    try {
      let _list = store.getState().basket;
      for (var i = 0; i < _list.length; i++) {
        if (_list[i].id === food.id && _list[i].pid === food.pid) {
          if (_list[i].number == 1) _list.splice(i, 1);
          else _list[i].number = _list[i].number - 1;
          break;
        }
      }
      await BasketApi.addtoBasketFoods(food.oid, food.mid, _list, jwt);

      await dispatch({ type: "ADD", payload: _list });
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
};
