import React from "react";

import Panel from "../../../layouts/Panel";
import { Avatar, List, Skeleton } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import Chart from "./components/Chart";
import Info from "./components/Info";

const Home = () => {
  const data2 = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
  ];
  return (
      <div className="home">
        <Chart />
        <div className="information">
          <Info />
          <div id="scrollableDiv" className="border border-1 rounded chat-log">
            <InfiniteScroll
              dataLength={1}
              scrollableTarget="scrollableDiv"
              next={() => {}}
              hasMore={data2.length < 50}
              loader={
                <Skeleton
                  avatar
                  paragraph={{
                    rows: 1,
                  }}
                  active
                />
              }
            >
              <List
                itemLayout="horizontal"
                header={<div className="fw-bold">آخرین پیام ها</div>}
                dataSource={data2}
                className={"p-2"}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar src="https://joeschmoe.io/api/v1/random" />
                      }
                      title={<a >{item.title}</a>}
                      description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                  </List.Item>
                )}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
  );
};

export default Home;
