import React, { useState } from 'react'
import MealsHistory from "../../components/MealsHistory";
import { useEffect } from "react";
import SelectDate from "../../../../commons/SelectDate";
import Meals from "../../../order/components/Meals";
import moment from "jalali-moment";

const ActivePackage = () => {
  const [date, setDate] = useState(moment());

    const changeSelectedDate = (date) => {
        setDate(date);
      };
  return (
    <div className="mt-3">
    <div>
      <SelectDate
        labelDate={false}
        state={2}
        changeSelectedDate={changeSelectedDate}
      />
    </div>
    <div>
      <MealsHistory selectedDate={date} />
    </div>
  </div>
  )
}

export default ActivePackage