import { useEffect, useRef } from "react";
import { useLocation } from "react-router";

const ScrolltoTop = (props) => {
  const location = useLocation();
  const prevLocRef = useRef();

  useEffect(() => {
    // console.log(location,prevLocRef.current)
    // prevLocRef.current=location
    if(!location.hash)
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

export default ScrolltoTop;