import React from "react";
import { MdFavoriteBorder } from "react-icons/md";
import ModifyH from "./ModifyH";
const ItemsBasket= (props) => {
  return (
    <div className="food  d-grid position-relative">
      <div className="img w-100">
        {" "}
        <img src={props.food?.product?.cover} alt="" className="w-100" />
      </div>
      
      <div className="info p-3">
        <div className=" d-f-j-a">
          <h6>{props.food.product?.name}</h6>
        </div>
        <h6 className="w-100 float-end text-start">
          {props.food.product?.price} <small className="fw-normal">تومان</small>
        </h6>
        <div className="w-100 ">

          <ModifyH food={props.food} disabled={true} />
        </div>
      </div>
    </div>
  );
};

export default ItemsBasket;
