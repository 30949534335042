import { Progress } from "antd";
import React, { useEffect, useState } from "react";
import { BiPyramid } from "react-icons/bi";
import { useSelector } from "react-redux";
import ComputeBMI from "./ComputeBMI";
const BMI = () => {
  const [active, setActive] = useState(false);
  const [, foceupdate] = useState(0);
  const [calories, setCalories] = useState(0);
  const [carbo, setCarbo] = useState(0);
  const [pro, setPro] = useState(0);
  const [fat, setFat] = useState(0);
  const bmi = useSelector((state) => state.bmi);
  const packet = useSelector((state) => state.packet);
  useEffect(() => {
    setCalories(packet?.nutrition_values?.user_calories ?? 0);
    setCarbo(packet?.nutrition_values?.total_cho ?? 0);
    setPro(packet?.nutrition_values?.total_pro ?? 0);
    setFat(packet?.nutrition_values?.total_fat ?? 0);
    //  foceupdate(Math.random())
  }, [packet]);
  useEffect(() => {
    if (bmi) setActive(1);
    else setActive(0);
  }, [bmi]);
  return (
    <div className="bmi mb-5">
      <ComputeBMI />
      <h6 className="mt-4">
        <BiPyramid className="ms-2" />
        جمع ارزش غذایی در این روز
      </h6>
      <div className="compute-bmi" style={{ opacity: active ? 1 : 0.4 }}>
        <div className="item">
          <div className="d-flex justify-content-between align-items-center">
            <span>کالری</span>
            <span>{calories} kcal</span>
          </div>
          <Progress percent={0} />
        </div>
        <div className="item">
          <div className="d-flex justify-content-between align-items-center">
            <span>کربوهیدرات</span>
            <span>{carbo} g</span>
          </div>
          <Progress percent={0} />
        </div>
        <div className="item">
          <div className="d-flex justify-content-between align-items-center">
            <span>پروتئین</span>
            <span>{pro} g</span>
          </div>
          <Progress percent={0} />
        </div>
        <div className="item">
          <div className="d-flex justify-content-between align-items-center">
            <span>چربی</span>
            <span>{fat} g</span>
          </div>
          <Progress percent={0} />
        </div>
      </div>
    </div>
  );
};

export default BMI;
