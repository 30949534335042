import {Result } from "antd";

import { Button, Spin } from "antd";

import { useNavigate } from "react-router-dom";
import Master from "../layouts/Master";

const Login = () => {
  const navigate=useNavigate();


  return (
    <Result
    status="403"
    title="403"
    subTitle="لطفا در ابتدا وارد پنل کاربری خود شوید یا ثبت نام کنید"
    extra={<Button onClick={()=>navigate("/")} type="primary">برگشت به صفحه اصلی </Button>}
  />
  );
};
export default Login;
