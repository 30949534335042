import Global from "../config.json";
import httpService from "../services/httpService";
const PackageApi={
    async loadPackage(id) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}package/${id}/`)
          .then((json) => {
              console.log("load package",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
      async loadActivePackages(jwt) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}package/user-package`,{
            headers:jwt
          })
          .then((json) => {
              console.log("load active package",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
      async startPackage(id,jwt) {
        let res = [];
        await httpService
          .post(`${Global.HealyMealy}package/${id}/user/`,{},{
            headers:jwt
          })
          .then((json) => {
              console.log("start package",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
      async deletePackage(pid,jwt) {
        let res = [];
        await httpService
          .delete(`${Global.HealyMealy}package/${pid}/user/`,{
            headers:jwt
          })
          .then((json) => {
              console.log("load package",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
      async packageCalendar(pid,puid,jwt){
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}package/${pid}/user/${puid}/`,{
            headers:jwt
          })
          .then((json) => {
              console.log("load calendar package",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
      async loadPacket(id) {
        let res = [];
        await httpService
          .get(`${Global.HealyMealy}package/`)
          .then((json) => {
              console.log("load packet",json)
            switch (json.status) {
              case 201:
              case 200:
                res = json.data
                break;
            }
          })
        return res;
      },
}
 export default PackageApi;