import { Button, Steps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { RiShoppingBasketLine } from "react-icons/ri";
import {
  MdOutlineArrowBackIos,
  MdOutlineDeliveryDining,
  MdOutlineAccountBalanceWallet,
} from "react-icons/md";
import Master from "../../layouts/Master";
import ListBasket from "./components/ListBasket";
import MainContext from "../../context/MainContext";
import BasketApi from "../../api/BasketApi";
import { useSelector } from "react-redux";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import BasketInfo from "./components/BasketInfo";

const { Step } = Steps;
const Basket = () => {
  const jwt=useSelector(state=>state.jwt)
  const context = useContext(MainContext);
  const [step, setStep] = useState(0);
  useEffect(() => {
    context.checkLogin();
  }, []);
  const doPayment=async()=>{
    try {
        const _res=await BasketApi.doPayment(jwt)
        window.location.href=_res.url
    } catch (error) {
      context.handleMsg(error)
    }
  }
  const submitText=()=>{
    switch(step){
      case 0:
        return <><MdOutlineDeliveryDining size={20} className="  ms-1" />  زمان و مکان تحویل</>;
        case 1:
          return <><MdOutlineAccountBalanceWallet  size={18} className="  ms-1" /> نوع پرداخت</>;
          case 2:
            return <>  ثبت سفارش <MdOutlineArrowBackIos className="  ms-1"  /></>;
    }
  }
  const onSetStep=()=>{
    if(step==2)
      doPayment()
    else setStep(step + 1)
  }
  return (
      <div className="basket container order">
        <div>
          <Steps
            current={step}
            className=" border border-1 rounded-3 p-1 pt-3 p-sm-3 ps-sm-5 pe-sm-5 ant-steps-horizontal  "
          >
            <Step title="ثبت پرداخت" icon={<RiShoppingBasketLine />} />
            <Step
              title="زمان و مکان تحویل"
              icon={<MdOutlineDeliveryDining />}
            />
            <Step title="نوع پرداخت" icon={<MdOutlineAccountBalanceWallet />} />
          </Steps>
          <div className="steps">
            <div style={{ display: step === 0 ? "" : "none" }}>
              <Step1 />
            </div>
            <div style={{ display: step === 1 ? "" : "none" }}>
              <Step2 />
            </div>
            <div style={{ display: step === 2 ? "" : "none" }}>
              <Step3 />
            </div>
          </div>
        </div>
        <div>
          <div className="basket-info">
           <BasketInfo />
            <div className="mt-5">
              <Button
                type="primary"
                shape="round"
                onClick={onSetStep}
                className="w-100  mt-2 mb-4 c-btn btn-success border border-0 "
              >
                {submitText()}
              </Button>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Basket;
