import React from 'react'
import  Footer  from './Footer';
import Header from './Header';
import { Outlet } from 'react-router-dom';

const  Master=({children}) =>{
  return (
    <>
    <Header />
    <div >  <Outlet /></div>
    <Footer />
    </>

  )
}

export default Master;