import React, { useContext, useEffect, useState } from "react";

import FoodApi from "../../api/FoodApi";
import ItemFood from "../../commons/ItemFood";
import MainContext from "../../context/MainContext";
import Master from "../../layouts/Master";
import Tools from "./components/Tools";
const Foods = () => {
  const context = useContext(MainContext);

  const [foods, setFoods] = useState([]);
  const loadFoods = async (q) => {
    try {
      let _foods = await FoodApi.loadFoods(q);
      setFoods(_foods);
    } catch (e) {
      context.showMsg(e);
    }
  };
  useEffect(() => {
    loadFoods("");
  }, []);
 
  return (
      <div className="container foods">
        <Tools loadFoods={loadFoods}/>
        <hr />
        <div className="foods-list">
          {foods?.map((food,index) => {
            return <ItemFood disabled={false} food={food} key={index} />;
          })}
        </div>
      </div>
  );
};

export default Foods;
