import React, { forwardRef, useContext, useImperativeHandle, useState } from "react";
import QuestionsAPI from "../../../../../api/QuestionsAPI";
import QuestionContext from "../../../../../context/QuestionContext";
import InputType from "../../tools/InputType";
import { useSelector } from "react-redux";
const Step2 = forwardRef((props,ref) => {
  const jwt=useSelector(state=>state.jwt)
  const questionContext = useContext(QuestionContext);
  const [, forceUpdate] = useState(0);
  const preprocessData=async ()=>{
    let _answers=[]
    questionContext?.questions["anthropometry"]?.map((item,index)=>{
      if(item?.newAnswer) questionContext.setProcessStep2(index,11)
      else questionContext.setProcessStep2(index,0)
      _answers.push({question:item.id,answer:{answer_type:item.answer_type,answer:item.newAnswer}})
    })
    return _answers
  }
  useImperativeHandle(ref,()=>({
    async onSubmit(){
      const _data=await preprocessData()
      console.log(_data)
     const _res=await QuestionsAPI.sendStepsData(_data,jwt)
  }
}))
  const onUpdate = () => {
    forceUpdate(Math.random());
  };
  return (
    <>
      <div className="d-f-j-a">
        {questionContext?.questions["anthropometry"]?.map((item, index) => {
          if (index <= 4)
            return (
              <div className="d-grid" key={index}>
                <label>{item.text}</label>
                <InputType
                  data={item}
                  questions={questionContext?.questions["anthropometry"]}
                  onUpdate={onUpdate}
                />
              </div>
            );
        })}
      </div>
      {questionContext?.questions["anthropometry"]?.map((item, index) => {
        if (index > 4)
          return (
            <div className="d-grid mt-3" key={index}>
              <label>{item.text}</label>
              <InputType data={item}  questions={questionContext?.questions["anthropometry"]} onUpdate={onUpdate} />
            </div>
          );
      })}
    </>
  );
});

export default Step2;
