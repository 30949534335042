import { Carousel } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CommentsAPI from "../../../api/CommentsAPI";
const settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  // margin:20,
  // autoHide:true,
  centerPadding: "10px",
  dots: false,
  slidesToScroll: 1,

  slidesToShow: 3,
  // className:"persons m-0",
  responsive: [
    {
      breakpoint: 480,
      settings: {
        centerMode: true,
        centerPadding: "0px",
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
const Blogs = () => {
  const navigate = useNavigate();
  // const [comments, setComments] = useState([]);

  // useEffect(() => {
  //   loadComments();
  // }, []);
  // const loadComments = async () => {
  //   try {
  //     const _res = await CommentsAPI.loadLanding();
  //     setComments(_res);
  //   } catch (e) {}
  // };
  const showPack = (id) => {
    navigate("/pack/1");
  };
  return (
    <div id="blog" className="blog mt-5 pt-5 mb-5 pb-5"  dir="ltr">
      <div className="container">
        <div className="title mb-5 text-center w-100">
          <h2 className="c-orange">بلاگ</h2>
        </div>
        {/* <div className="blog-slider" dir="ltr"> */}
        <Carousel {...settings}>
        
          <div className="item position-relative ">
            <span className="date"> 2 روز پیش</span>
            <span className="titr">غذاهای مفیدی که خوبه</span>
            <img  src="/images/blog/1.png" />
          </div>
          <div className="item position-relative  ">
            <span className="date rounded"> 2 روز پیش</span>
            <span className="titr rounded">غذاهای مفیدی که خوبه</span>
            <img  src="/images/blog/3.png" />
          </div>
          <div className="item position-relative  ">
            <span className="date rounded"> 2 روز پیش</span>
            <span className="titr rounded">غذاهای مفیدی که خوبه</span>
            <img  src="/images/blog/2.png" />
          </div>
        
        </Carousel>
        {/* </div> */}
      </div>
      <h5 className="show-all c-orange float-start ps-3">مشاهده همه</h5>
    </div>
  );
};

export default Blogs;
