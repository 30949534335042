import { Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MenuProfile from "../commons/MenuProfile";
import MainContext from "../context/MainContext";
import { RiUser3Line } from "react-icons/ri";
import { MdLogin } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
const Header = () => {
  const jwt = useSelector((state) => state.jwt);
  const context = useContext(MainContext);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const navigate = useNavigate();
  const hideProfile = () => {
    setProfileVisible(false);
  };

  const handleProfileVisibleChange = (newVisible) => {
    setProfileVisible(newVisible);
  };
  const showLogin = () => {
    context.checkLogin();
  };
  const toggleBurgerMenu = () => {
    // alert(window?.innerWidth)
    if (window.innerWidth <= 480) setBurgerMenu(!burgerMenu);
  };
  useEffect(() => {}, [jwt]);
  const checkAuth = (type) => {
    if (jwt)
      if (type === "mobile") {
        return (
          <div className="mobile">
            {/* <img src="/icon/register.svg" className="icon-register2 d-none" /> */}
            <RiUser3Line size={30} color={"#6E9947"} />

            <span onClick={() => navigate("/panel/home")} className="d-block">
              پروفایل
            </span>
          </div>
        );
      } else
        return (
          <div className="desktop">
            <svg
              width="40"
              height="40"
              className="ms-3"
              role={"button"}
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 10C21.5913 10 23.1174 10.6321 24.2426 11.7574C25.3679 12.8826 26 14.4087 26 16V17H30V19H28.833L28.076 28.083C28.0552 28.3329 27.9413 28.5658 27.7568 28.7357C27.5723 28.9055 27.3308 28.9999 27.08 29H12.92C12.6692 28.9999 12.4277 28.9055 12.2432 28.7357C12.0587 28.5658 11.9448 28.3329 11.924 28.083L11.166 19H10V17H14V16C14 14.4087 14.6321 12.8826 15.7574 11.7574C16.8826 10.6321 18.4087 10 20 10ZM26.826 19H13.173L13.84 27H26.159L26.826 19ZM21 21V25H19V21H21ZM17 21V25H15V21H17ZM25 21V25H23V21H25ZM20 12C18.9738 12 17.9868 12.3944 17.2432 13.1017C16.4996 13.809 16.0563 14.775 16.005 15.8L16 16V17H24V16C24 14.9738 23.6056 13.9868 22.8983 13.2432C22.191 12.4996 21.225 12.0563 20.2 12.005L20 12Z"
                fill="#6E9947"
              />
              <circle cx="20" cy="20" r="19.5" stroke="#6E9947" />
            </svg>

            <Popover
              content={<MenuProfile />}
              title=""
              trigger="click"
              visible={profileVisible}
              onVisibleChange={handleProfileVisibleChange}
            >
              <svg
                width="40"
                height="40"
                role={"button"}
                className="ms-3 "
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28 30H26V28C26 27.2044 25.6839 26.4413 25.1213 25.8787C24.5587 25.3161 23.7956 25 23 25H17C16.2044 25 15.4413 25.3161 14.8787 25.8787C14.3161 26.4413 14 27.2044 14 28V30H12V28C12 26.6739 12.5268 25.4021 13.4645 24.4645C14.4021 23.5268 15.6739 23 17 23H23C24.3261 23 25.5979 23.5268 26.5355 24.4645C27.4732 25.4021 28 26.6739 28 28V30ZM20 21C19.2121 21 18.4319 20.8448 17.7039 20.5433C16.9759 20.2417 16.3145 19.7998 15.7574 19.2426C15.2002 18.6855 14.7583 18.0241 14.4567 17.2961C14.1552 16.5681 14 15.7879 14 15C14 14.2121 14.1552 13.4319 14.4567 12.7039C14.7583 11.9759 15.2002 11.3145 15.7574 10.7574C16.3145 10.2002 16.9759 9.75825 17.7039 9.45672C18.4319 9.15519 19.2121 9 20 9C21.5913 9 23.1174 9.63214 24.2426 10.7574C25.3679 11.8826 26 13.4087 26 15C26 16.5913 25.3679 18.1174 24.2426 19.2426C23.1174 20.3679 21.5913 21 20 21ZM20 19C21.0609 19 22.0783 18.5786 22.8284 17.8284C23.5786 17.0783 24 16.0609 24 15C24 13.9391 23.5786 12.9217 22.8284 12.1716C22.0783 11.4214 21.0609 11 20 11C18.9391 11 17.9217 11.4214 17.1716 12.1716C16.4214 12.9217 16 13.9391 16 15C16 16.0609 16.4214 17.0783 17.1716 17.8284C17.9217 18.5786 18.9391 19 20 19Z"
                  fill="#6E9947"
                />
                <circle cx="20" cy="20" r="19.5" stroke="#6E9947" />
              </svg>
            </Popover>
          </div>
        );
    else if (type === "mobile")
      return (
        <div className="mobile" onClick={showLogin}>
          <MdLogin size={30} color={"#6E9947"} />

          <span className="d-block">ورود</span>
        </div>
      );
    else
      return (
        <div className="box-btn--white" id="register">
          <button onClick={showLogin} className="btn btn--white btn-no-motion">
            ورود | ثبت نام
            <img src="/icon/register.svg" className="icon-register" />
          </button>
        </div>
      );
  };
  
  return (
    <header>
      <div className="container-fluid pe-3 ps-3">
        <div className="wrapper container">
          <nav className="navbar">
            <div className="burger" onClick={toggleBurgerMenu} id="burger">
              <span className="burger-line"></span>
              <span className="burger-line"></span>
              <span className="burger-line"></span>
            </div>
            <span
              onClick={toggleBurgerMenu}
              className={`overlay ${burgerMenu ? "is-active" : ""} `}
            ></span>
            <div
              // onClick={toggleBurgerMenu}
              className={`menu ${burgerMenu ? "is-active" : ""}`}
              id="menu"
            >
              <ul className="menu-inner">
                <li className="menu-item">
                  <Link onClick={toggleBurgerMenu} className="menu-link" to="/">
                    صفحه اصلی
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    onClick={() => {
                      context.scrollToEl("packages");

                      toggleBurgerMenu();
                    }}
                    className="menu-link"
                    to="/#packages"
                  >
                    سفارش غذا
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    onClick={toggleBurgerMenu}
                    className="menu-link"
                    to="consultants"
                  >
                    مشاوره تغذیه
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    onClick={() => {
                      toggleBurgerMenu();
                      context.scrollToEl("packages");
                    }}
                    className="menu-link"
                    to="/#packages"
                  >
                    پکیج های غذایی
                  </Link>
                </li>
                <li className="menu-item">
                  <Link onClick={toggleBurgerMenu} className="menu-link" to="#">
                    بلاگ
                  </Link>
                </li>
                <li className="menu-item">
                  <Link
                    onClick={toggleBurgerMenu}
                    className="menu-link"
                    to="/about"
                  >
                    درباره ما
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div className="d-g-c-g">
            <div className="box-btn--green" id="download">
              <button className="btn motion--download btn--green">
                دانلود اپلیکیشن
              </button>
            </div>
            {checkAuth()}
            <div className="mobile vertical-line"></div>

            {checkAuth("mobile")}
            <div className="mobile vertical-line"></div>
            <div className="mobile">
              <img src="/icon/Download.svg" className="icon-register2 d-none" />
              <span className="d-block">دانلود</span>
            </div>
            <div className="mobile vertical-line"></div>
            <Link to="/" className="logo">
              <img src="/images/logo.png" />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
