import BasketApi from "../../api/BasketApi";
import storage from "../../services/storage";
import { store } from "../store";
export const loadCardAction = (data) => {
    return async (dispatch) => {
     
        await dispatch({ type: "ADD_CARD", payload: data });
     
    };
  };
  export const addCardAction = (food,mid, context, jwt) => {
    return async (dispatch) => {
      try {
        const _card = store.getState().card;
        console.log(food,_card,444)

        let _flagExist = false;
          _card.map(async(order, index) => {
            _flagExist = false;

            if (order.en_meal === mid) {

              order.product.map(async(p) => {
                if (p.id === food.id) {
                  _flagExist = true;
                  p.product.number +=1;
                  p.number += 1;
                  await BasketApi.addtoBasketFoods(order.cart, mid, [{product:p.product.id,number:p.number}], jwt);
                }
              });
            
            }
        });
  
        console.log(_card);
  
        await dispatch({ type: "UPDATE_CARD", payload: _card });
      } catch (e) {
        context.handleMsg(e, "error");
      }
    };
  };
  export const removeCardAction = (food,mid, context, jwt) => {
    return async (dispatch) => {
      try {
        const _card = store.getState().card;
        console.log(food,_card,444)

        let _flagExist = false;
          _card.map(async(order, index) => {
            _flagExist = false;

            if (order.en_meal === mid) {

              order.product.map(async(p) => {
                if (p.id === food.id) {
                  _flagExist = true;
                  p.product.number -=1;
                  p.number -= 1;
                  await BasketApi.addtoBasketFoods(9, mid, [{product:p.product.id,number:p.number}], jwt);
                }
              });
            
            }
        });
  
        console.log(_card);
  
        await dispatch({ type: "UPDATE_CARD", payload: _card });
      } catch (e) {
        context.handleMsg(e, "error");
      }
    };
  };
  
export const updateCardAction = (context) => {
  return async (dispatch) => {
    try {
      const _basket = store.getState().basket;
      const _card = store.getState().card;
      let _flagExist = false;
      _basket.map((recipe, index) => {
        _card.map((order, index) => {
          _flagExist = false;
          if (order.en_meal == recipe.mid) {
            order.product.map((p) => {
              if (p.product.id === recipe.product) {
                _flagExist = true;
                p.product.number += recipe.number;
                p.number += recipe.number;
              }
            });
            if (_flagExist === false)
              order.product.push({
                number: recipe.number,
                product: {
                  id: recipe.product,
                  name: recipe.name,
                  number: recipe.number,
                },
              });
          }
        });
      });

      console.log(_card);

      await dispatch({ type: "UPDATE_CARD", payload: _card });
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
};
