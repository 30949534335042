import React, { useState } from "react";
import { useEffect } from "react";
import CartApi from "../../../../api/CartApi";
import { useSelector } from "react-redux";
const Info = () => {
  const jwt=useSelector(state=>state.jwt)
  const [info, setInfo] = useState(null)
  const loadInfo=async()=>{
    try {
      const _res=await CartApi.loadOrdersState(jwt)
      setInfo(_res)
    } catch (error) {
      
    }
  }
  useEffect(() => {loadInfo()}, []);
  return (
    <div>
      <div className="border border-1 rounded mb-3 p-2 ">
        <div className="d-f-j-a">
          <span className="p-3">وعده های رزرو شده</span>
          <span className="p-3">{info?.reserve}</span>
        </div>
        <div className="d-f-j-a">
          <span className="p-3">وعده های تحویل شده</span>
          <span className="p-3">{info?.delivered}</span>
        </div>
        <div className="d-f-j-a">
          <span className="p-3">وعده باقی مانده</span>
          <span className="p-3">{info?.remain}</span>
        </div>
      </div>
      <div className="border border-1 rounded p-2">
        <div className="d-f-j-a">
          <span className="p-3">پکیج رژیم غذایی</span>
          <span className="p-3">{info?.regimen_package==false?"غیر فعال":"فعال"}</span>
        </div>
        <div className="d-f-j-a">
          <span className="p-3">پکیج رزرو شده</span>
          <span className="p-3">{info?.reserved_package}</span>
        </div>
      </div>
    </div>
  );
};

export default Info;
