import axios from "axios";
// import { toaster } from "rsuite";
// import message from "../components/common/Message";
// import storage from "./storage";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Accept-language"] = "fa";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";
axios.defaults.headers.get["Accept-language"] = "fa";
axios.defaults.headers.get["Content-Type"] = "application/json";
// axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.post["Access-Control-Allow-Headers"] = "content-type";
// axios.defaults.headers.post["Access-Control-Max-Age"] = "1800";
// axios.defaults.headers.post["Access-Control-Allow-Methods"] = "POST";

// 'Access-Control-Allow-Origin': '*',
// 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
axios.interceptors.response.use(null, (error) => {
  // const expectedErrors =
  //   error.response &&
  //   error.response.status >= 400 &&
  //   error.response.status != 401 &&
  //   error.response.status < 500;

  // if (!expectedErrors) {
 
  //   if (error.response.status == 401) {
  //     // localStorage.removeItem("token");
  //         // window.location.reload(false);
     
  //   }
  //   // toaster.push(message("لطفا در ابتدا لاگین کنید", "error"), {
  //   //   placement: "topStart",
  //   // });
  //   else {
  //     alert(56)
  //     console.log(error);
  //     // toaster.push(message("مشکلی از سمت سرور رخ داده است.", "error"), {
  //     //   placement: "topStart",
  //     // });
  //   }
  // }

  return Promise.reject(error);
});

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
