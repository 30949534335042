import { Avatar, Breadcrumb, Divider, Dropdown, Menu, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  MdOutlineSpaceDashboard,
  MdFavoriteBorder,
  MdLogout,
  MdOutlineConnectWithoutContact,
} from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  BiMessageDetail,
  BiUserCircle,
} from "react-icons/bi";
import { CgList } from "react-icons/cg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeJWTAction } from "../redux/actions/jwtActions";
import UserApi from "../api/UserApi";

import { useContext } from "react";
import MainContext from "../context/MainContext";
import { IoIosArrowDropdown, IoIosArrowDropleft, IoIosArrowDropup, IoMdArrowDropdown } from "react-icons/io";
const Sidebar = () => {
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false)
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const jwt = useSelector((state) => state.jwt);
  const dispatch = useDispatch();
  const context = useContext(MainContext);

  const loadProfile = async () => {
    try {
      const _res = await UserApi.loadProfile(jwt);
      setProfile(_res);
    } catch (e) {
      context.handleMsg(e, "error");
    }
  };
  useEffect(() => {
    if (jwt) loadProfile();
  }, [jwt]);
  const checkPath = (path) => {
    if (location.pathname.search(path) !== -1) return "active";
    return "";
  };
  const logOut = () => {
    dispatch(removeJWTAction());
    navigate("/");
  };
  const menu = (
    <Menu
      selectable
      style={{ float: "right", textAlign: "right", width: "100%" }}
      defaultSelectedKeys={["1"]}
      items={[
        {
          key: "1",
          label: (
            <Link className="text-dark d-block" to={"/panel/home"}>
              <MdOutlineSpaceDashboard size={24} /> داشبورد
            </Link>
          ),
        },
        {
          key: "2",
          label: (
            <Link className="text-dark d-block" to={"/panel/orders"}>
              <CgList size={24} /> سفارش ها
            </Link>
          ),
        },
        {
          key: "3",
          label: (
            <Link className="text-dark d-block" to={"/panel/consultant"}>
              <MdOutlineConnectWithoutContact size={24} /> مشاوره تغذیه
            </Link>
          ),
        },
        {
          key: "4",
          label: (
            <Link className="text-dark d-block" to={"/panel/list"}>
              <MdFavoriteBorder size={24} /> لیست من
            </Link>
          ),
        },
        {
          key: "5",
          label: (
            <Link className="text-dark d-block" to={"/panel/"}>
              <BiMessageDetail size={24} /> پیام ها
            </Link>
          ),
        },
        {
          key: "6",
          label: (
            <Link className="text-dark d-block" to={"/panel/profile"}>
              {" "}
              <BiUserCircle size={24} /> حساب کاربری
            </Link>
          ),
        },
      ]}
    />
  );
  const onVisibleChange=(visible)=>{
    setMenuVisible(visible)
  }
  return (
    <>
      <div className="sidebar">
        <ul className="list-group">
          <li className="list-group-item">
            <div className="d-flex align-items-center">
              <Avatar size={"large"} src={profile?.avatar?.image} />
              <h6 className="pe-3 mt-2"> {profile?.nickname}</h6>
            </div>
          </li>
          <li className={`list-group-item ${checkPath("/panel/home")}`}>
            <Link className="text-dark d-block" to={"/panel/home"}>
              <MdOutlineSpaceDashboard size={24} /> داشبورد
            </Link>
          </li>
          <li className={`list-group-item ${checkPath("/panel/orders")}`}>
            <Link className="text-dark d-block" to={"/panel/orders"}>
              <CgList size={24} /> سفارش ها
            </Link>
          </li>
          <li className={`list-group-item  ${checkPath("/panel/consultant")}`}>
            <Link className="text-dark d-block" to={"/panel/consultant"}>
              <MdOutlineConnectWithoutContact size={24} /> مشاوره تغذیه
            </Link>
          </li>
          <li className={`list-group-item  ${checkPath("/panel/list")}`}>
            <Link className="text-dark d-block" to={"/panel/list"}>
              <MdFavoriteBorder size={24} /> لیست من
            </Link>
          </li>
          <li className="list-group-item">
            <BiMessageDetail size={24} /> پیام ها
          </li>
          <li className={`list-group-item  ${checkPath("/panel/profile")}`}>
            {" "}
            <Link className="text-dark d-block" to={"/panel/profile"}>
              {" "}
              <BiUserCircle size={24} /> حساب کاربری
            </Link>
          </li>
          <li className="list-group-item">
            <a className="text-dark d-block" onClick={logOut}>
              {" "}
              <MdLogout size={24} /> خروج از حساب
            </a>
          </li>
        </ul>
      </div>
      <div className="mobile-sidebar p-2">
        <div
          className="d-flex align-items-center p-2"
          style={{ background: "#F9F9F9" }}
        >
          <Avatar size={"large"} src={profile?.avatar?.image} />
          <h6 className="pe-3 mt-2"> {profile?.nickname}</h6>
        </div>
        <Dropdown
          overlay={menu}
          direction={"rtl"}
          className="p-2"
          onVisibleChange={onVisibleChange}
        >
          <div className="d-f-j-a">
            <Breadcrumb separator=">" color="#6E9947">
              <Breadcrumb.Item style={{color:"#6E9947"}}>
                <IoMdArrowDropdown color="#6E9947" />
                <GiHamburgerMenu color="#6E9947" size={20} className={"ps-1"} />
                پیام ها
              </Breadcrumb.Item>
              <Breadcrumb.Item  style={{color:"#6E9947"}}>تیکت</Breadcrumb.Item>
            </Breadcrumb>
          {menuVisible?
            <IoIosArrowDropdown color="#6E9947" size={20} />

          :
          <IoIosArrowDropleft color="#6E9947" size={20} />

          }
          </div>
        </Dropdown>
        <Divider className="m-1" />
      </div>
    </>
  );
};

export default Sidebar;
