import React from "react";
import { MdFavoriteBorder } from "react-icons/md";
import ModifyH from "./ModifyH";
import { useSelector } from "react-redux";
const ItemFood = (props) => {
  const basket = useSelector((state) => state.basket);
  const checkExistBasket=(food)=>{
    console.log(basket,555,food,555)

    const _res=basket.find(b=>food.id===b.id)
    return _res
  }
  return (
    <div className="food  d-grid position-relative">
      <div className="img w-100">
        {" "}
        <img src={props.food.cover} alt="" className="w-100" />
      </div>
      <div className="calories">
        {!props?.disabled &&
          props?.food?.values.map((value, index) => (
            <div className="d-grid" key={index}>
              <span>{value.attribute}</span>
              <h6 dir="ltr">
                {" "}
                {value.value} {value.unit}
              </h6>
            </div>
          ))}
      </div>
      <div className="info p-3">
        <div className=" d-f-j-a">
          <h6>{props.food.name}</h6>
        </div>
        <h6 className="w-100 float-end text-start">
          {props.food.price} <small className="fw-normal">تومان</small>
        </h6>
        <div className="d-f-j-a w-100">
          <MdFavoriteBorder size={24} role="button" />

          <ModifyH food={props.food}  data={checkExistBasket(props.food)}/>
        </div>
      </div>
    </div>
  );
};

export default ItemFood;
