import React from "react";
import ContactUs from "./components/ContactUs";

const About = () => {
  return (
      <div className="about">
        <section className="w-100  description">
          <div className="box">
            <div className="titr">
              “ای کاش می‌شد هر کاری دوست دارم انجام بدم،
              <br />
              هر چیزی که دوست دارم بخورم و سالم بمونم!”
            </div>
            <div className="text">
              <p>
                <span className="bold" style={{ color: "#EC772D" }}>
                  این چیزیه که خیلی از ما بارها بهش فکر کردیم و آرزو کردیم.
                </span>
              </p>
              <p className="fw-bold">
                {" "}
                یه نکته تو جمله بالا وجود داره که می‌تونه کلید به واقعیت پیوستن
                این آرزو باشه:
              </p>
              <p>
                “هر چیزی/ هر کاری که دوست دارم” تا حالا فکر کردید دوست داشتن و
                نداشتن از کجا میاد؟ مثلا چرا اکثر کسایی که توی مرکز کشور زندگی
                می‌کنند با ماهی و غذاهای دریایی رابطه چندان خوبی ندارند، ولی
                عاشق قرمه‌سبزی و کباب هستند؟
              </p>
              <p>
                ثابت شده که عوامل مختلفی از فرهنگ و خانواده و جامعه گرفته تا
                تجربیات، شرایط زندگی و خلق فردی ما روی شکل گرفتن علاقه‌ها و
                عادت‌هامون تاثیرگذار هستند. به جز این‌ها، جامعه صنعتی عادت‌هایی
                رو به ما دیکته کرده که ما رو مریض می‌کنند؛ در واقع وادارمون
                می‌کنه خلاف میل طبیعی وجودمون عمل کنیم. هیلی میلی یک سبک زندگیه؛
                همون سبک زندگی که ما رو عادت می‌ده چیزهایی رو دوست داشته باشیم
                که حالمون رو خوب می‌کنه.
              </p>
              <p>
                هیلی میلی رو ما اختراع نکردیم، فقط کشفش کردیم! شما هم می‌تونید
                هیلی میلی وجود خودتون رو کشف کنید و به واسطه انجام دادن کارهایی
                که دوست دارید، حالتون رو بهتر کنید.
              </p>
            </div>
          </div>
        </section>
        <section className="map">
          <iframe
          title="location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12956.120652316185!2d51.3862661!3d35.7254767!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x27b49be1782e8b86!2sNextera!5e0!3m2!1sen!2s!4v1659950193451!5m2!1sen!2s"
            width="100%"
            height="480"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
          <div className="address">
            <span className="fw-bold">آدرس:</span>
            <span>خیابان کارگر شمالی، بالاتر از جلال آل احمد، دانشکده فنی دانشگاه تهران، دانشکده مهندسی مکانیک.</span>
            <span className="fw-bold float-start"> 021-77036895  </span> <span className="fw-bold float-start"> تلفن تماس:</span>
          </div>
        </section>
        <section>
            <ContactUs/>
        </section>
      </div>
  );
};

export default About;
