import React from "react";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { BsQuestionCircle } from "react-icons/bs";
import Master from "../../../layouts/Master";
import Panel from "../../../layouts/Panel";
import {TiMessages} from "react-icons/ti"
import Consultants from "./components/Consultants";
import SelectDays from "./components/SelectDays";
import { CgFileDocument } from "react-icons/cg";

const ListConsultants = () => {
  return (
    <>
      <div className="consultants mb-4">
        <div className=" container p-0">
          <Consultants />
          <SelectDays />
        </div>
        <div className="container p-sm-0 mt-4 description">
          <h6 className="c-green fw-bold mb-4 ">مراحل دریافت مشاور تغذیه</h6>
          <div className="d-flex">
            <div className="icon-border">
              <BsQuestionCircle className="icon" size={34} color={"#EC772D"} />
            </div>
            <div className="text">
              <h6>پرسشنامه اطلاعات جسمی و تغذیه</h6>
              <p>
                برای بررسی و تشخیص برنامه غذایی مناسب با شما، باید تعدادی
                سوالاتی را که به 5 گروه دسته بندی شده اند را جواب دهید. این 5
                دسته شامل: اطلاعات عمومی، تن سنجی، ارزیابی عمومی، ارزیابی بالینی
                و الگوی غذایی. هر چه سوالات را کامل و صادقانه جواب دهید،
                کارشناسان هیلی میلی برنامه ای بهتر را می توانند برای شما در نظر
                بگیرند.
              </p>
            </div>
          </div>
          

          <div className="d-flex mt-4">
            <div className="icon-border">
              <MdOutlineAccountBalanceWallet className="icon" size={34} color={"#EC772D"} />
            </div>
            <div className="text">
              <h6>پرداخت هزینه مشاور</h6>
              <p>
              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی 
              </p>
            </div>
          </div>

          <div className="d-flex mt-4">
            <div className="icon-border">
              <TiMessages className="icon" size={34} color={"#EC772D"} />
            </div>
            <div className="text">
              <h6>تماس مشاور تغذیه</h6>
              <p>
              چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.               </p>
            </div>
          </div>

          <div className="d-flex mt-4">
            <div className="icon-border">
              <CgFileDocument className="icon" size={34} color={"#EC772D"} />
            </div>
            <div className="text">
              <h6>دریافت برنامه غذایی  </h6>
              <p>
              در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.         
              </p>
                 </div>

          </div>

        </div>
      </div>
    </>
  );
};

export default ListConsultants;
