import { Button } from "antd";
import React from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Panel from "../../layouts/Panel";

const Consultant = () => {
  const navigate=useNavigate()
  useEffect(()=>{
  },[])
  return (
    <>
      <div className="consultant">
        <h6>وضعیت حمایت کارشناس</h6>
        <div className="consultant-recieve">
          <span>شما در حال حاضر تحت نظر و حمایت کارشناس تغذیه نیستید.</span>
          
          <span className="mt-2">
            <Link to="/panel/consultant/list" shape="round" className="btn-outline-green">
            دریافت مشاوره تغذیه
            </Link>
          </span>
        </div>
      </div>
      <hr />
      <div></div>
    </>
  );
};

export default Consultant;
